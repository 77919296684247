import './css/login.css'
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../store';
import axios from 'axios';

function PasswordReset() {
    const [id, setId] = useState("")
    const [code, setCode] = useState("")
    const [check, setCheck] = useState(false)
    const [confirm, setConfirm] = useState(false)

    let dark = useSelector((state) => { return state.Darkmode.value });

    const darkname = dark ? 'dark' : "";

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
            }
            else {
                dispatch(checkLogin(true));
                navigate('/dashboard')
            }
        }
        else {
            dispatch(checkLogin(false));
        }
    }, [])

    const onChangeId = (e) => {
        setId(e.target.value)
    }

    const onChangeCode = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setCode(onlyNumber)
    }

    const onCheckEmail = async () => {
        await axios.get('https://api.admin.bitket.io/api/v1/admin/check/email', {
            params: {
                email: id
            }
        }).then((response) => {
            if (response.data.code === 1) {
                setCheck(true)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onSubmit = async () => {
        await axios.get('https://api.admin.bitket.io/api/v1/admin/check/certify', {
            params: {
                email: id,
                authCode: code,
            }
        }).then((response) => {
            if (response.data.code === 1) {
                handleClick()
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const handleClick = () => {
        setConfirm(true);

        setTimeout(() => {
            setConfirm(false);
        }, 3000);
    };

    return (
        <div className={`login ${darkname}`}>
            <div className='login-box'>
                <Link to={`/`}>
                    {
                        dark
                            ?
                            <img src='/img/logo-white.png' />
                            :
                            <img src='/img/logo-black.png' />
                    }
                </Link>
                {
                    confirm
                        ?
                        <div className={confirm ? 'verification-box-2-1 on' : 'verification-box-2-2'} style={{ color: "rgba(255, 163, 0, 1)" }}>
                            An temporary password has been sent to your email.<br />
                            Please confirm and use it to log in</div>
                        :
                        <div className='verification-box-2-1'>Authenticate via the email stored in your member information</div>
                }
                <div className='reset-box'>
                    <div className={`reset-box-1 ${darkname}`}>
                        <input value={id} onChange={onChangeId} placeholder='ID' />
                    </div>
                    <div className={`reset-box-1-btn ${darkname}`} onClick={() => onCheckEmail()}>Send code</div>
                </div>
                <div className={check ? 'reset-box' : 'reset-box on'} style={{ marginBottom: "10px" }}>
                    <div className={`reset-box-1 ${darkname}`}>
                        <input value={code} onChange={onChangeCode} maxLength={6} laceholder='Enter the verification code' />
                    </div>
                    <div className={`reset-box-1-btn ${darkname}`} onClick={() => onSubmit()}>Confirm</div>
                </div>
                <div className='reset-box-2'><span onClick={() => navigate('/')}>Log In</span> <FontAwesomeIcon icon={faAngleRight} /></div>
            </div>
        </div>
    )
}

export default PasswordReset;