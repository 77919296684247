import './css/main.css'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSortUp, faSortDown, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import Sidebar from '../Nav/sidebar';
import Page from './Page'
import NewPage from './NewPage'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../store';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function Setting() {

    const [page, setPage] = useState(1)
    const [btn, setBtn] = useState(false)
    const [data, setData] = useState([1])
    const [adminData, setAdminData] = useState([])
    const [reload, setReload] = useState(false)

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [mobile, setMobile] = useState("")
    const [select, setSelect] = useState(false)
    const [selectValue, setSelectValue] = useState("SUPER")
    const [phone, setPhone] = useState("82")


    // 유효성검사
    const [checkemail, setCheckEmail] = useState(false);
    const [checkmobile, setCheckMobile] = useState(false)
    const [checkpassword, setCheckPassword] = useState(false)
    const [checkname, setCheckname] = useState(false)

    const emailRegEx = /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/i;
    const passwordRegEx = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?'":{}|<>`;/])[A-Za-z\d!@#$%^&*(),.?'":{}|<>`;/]{6,15}$/;
    const nameRegEx = /^[a-zA-Z0-9]{2,10}$/;
    const regex5 = /^[0-9]{9,11}$/;

    const onChangeName = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^\\!-z]/gi, "")
        setName(onlyNumber)
        nameCheck(onlyNumber)
    }

    const onChangeEmail = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^\\!-z]/gi, "")
        setEmail(onlyNumber);
        emailCheck(onlyNumber)
    }

    const onChangePassword = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^a-zA-Z0-9!@#$%^&*()]/g, "")
        setPassword(onlyNumber)
        passwordCheck(onlyNumber)
    }

    const onChangeMobile = (e) => {
        const { value } = e.target;
        let onlyNumber = value.replace(/[^0-9]/g, '');
        setMobile(onlyNumber);
        mobileCheck(onlyNumber);
    }

    const onChangePhone = (e) => {
        setPhone(e.target.value);
    }


    const emailCheck = (username) => {
        if (emailRegEx.test(username)) setCheckEmail(true)
        else setCheckEmail(false);
    }

    const mobileCheck = (username) => {
        if (regex5.test(username)) setCheckMobile(true)
        else setCheckMobile(false);
    }

    const passwordCheck = (username) => {
        if (passwordRegEx.test(username)) setCheckPassword(true)
        else setCheckPassword(false)
    }

    const nameCheck = (username) => {
        if (nameRegEx.test(username)) setCheckname(true)
        else setCheckname(false)
    }


    let dark = useSelector((state) => { return state.Darkmode.value });
    let SelectType = useSelector((state) => { return state.SelectType.value });

    const darkname = dark ? 'dark' : "";

    let dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, [])

    useEffect(() => {
        if (SelectType != 0) navigate(`/dashboard`)
    }, [SelectType])

    const onAdduser = () => {
        let newarr = [...data, 1]
        setData(newarr)
    }

    useEffect(() => {
        MemberData()
    }, [page, reload])

    const MemberData = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get('https://api.admin.bitket.io/api/v1/admin/managers', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setAdminData(tmp.admin_members)
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const AddNewUSer = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            if (checkemail && checkmobile && checkpassword && checkname) {
                await axios.post('https://api.admin.bitket.io/api/v1/admin/manager', {
                    name: name,
                    email: email,
                    password: password,
                    mobile: mobile,
                    authority: selectValue
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then((response) => {
                    setReload(e => !e)
                    setName("")
                    setEmail("")
                    setPassword("")
                    setMobile("")
                    setSelectValue("SUPER")
                }).catch(error => {
                    console.error(error);
                });
            }
        }
    }

    const excelData = () => {
        let arr = []
        if (adminData) {
            for (let i = 0; i < adminData.length; i++) {
                let t = adminData[i]
                let reg = ""
                let login = ""
                let logout = ""

                if (t.registration_time) {
                    let tmpdate = t.registration_time
                    let formattedDate = `${tmpdate[0]}.${padZero(tmpdate[1])}.${padZero(tmpdate[2])} ${padZero(tmpdate[3])}:${padZero(tmpdate[4])}:${tmpdate[5] ? padZero(tmpdate[5]) : "00"}`;
                    reg = formattedDate
                }

                if (t.last_login_time) {
                    let tmpdate = t.last_login_time
                    let formattedDate = `${tmpdate[0]}.${padZero(tmpdate[1])}.${padZero(tmpdate[2])} ${padZero(tmpdate[3])}:${padZero(tmpdate[4])}:${tmpdate[5] ? padZero(tmpdate[5]) : "00"}`;
                    login = formattedDate
                }

                if (t.last_logout_time) {
                    let tmpdate = t.last_logout_time
                    let formattedDate = `${tmpdate[0]}.${padZero(tmpdate[1])}.${padZero(tmpdate[2])} ${padZero(tmpdate[3])}:${padZero(tmpdate[4])}:${tmpdate[5] ? padZero(tmpdate[5]) : "00"}`;
                    logout = formattedDate
                }

                arr.push({
                    "Name": t.name,
                    "Email": t.email,
                    "Mobile": t.mobile,
                    "Reg": reg ? reg : "-",
                    "LastLogin": login ? login : "-",
                    "LastLogout": logout ? logout : "-",
                    "Status": t.status,
                    "Level": t.authority
                })

            }
        }

        Excel(arr)
    }

    let slice_data = adminData.slice((page - 1) * 10, (page) * 10)

    return (
        <div className='main'>
            <Sidebar page={12} />
            <div className={`main-box ${darkname}`}>
                <div className='setting-1'>
                    <div style={{ width: "130px", fontWeight: "bold" }}>Admin Members</div>
                    <div className='cslog-1'>
                        <div className={`tradingType-1-2 ${darkname}`} onClick={() => excelData()}>Excel download</div>
                    </div>
                    <div className={`table-head ${darkname}`}>
                        <div>Name</div>
                        <div>Email</div>
                        <div>Mobile</div>
                        <div>Reg.date</div>
                        <div>Last login</div>
                        <div>Last logout</div>
                        <div>Status</div>
                        <div>Level</div>
                        <div style={{ width: "50%" }}></div>
                    </div>
                    <div className='table-body'>
                        {
                            slice_data && slice_data.length === 0
                                ?
                                <div className='data-nofound'>
                                    <img src='/img/img_nofound.png'></img>
                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                    <p>NO RECORDS FOUND.</p>
                                </div>
                                :
                                <>
                                    {
                                        slice_data.map(function (a, i) {
                                            return (
                                                <TradingTypeItem key={i} item={a} darkname={darkname} setReload={setReload} setPage={setPage} />
                                            )
                                        })
                                    }
                                    <NewPage initialData={adminData} page={page} setPage={setPage} maxFirstPage={10} />
                                </>

                        }
                    </div>
                </div>
                <div className='setting-2'>
                    <div style={{ width: "105px", fontWeight: "bold" }}>Add new user</div>
                    {/* <div className={`setting-2-1 ${darkname}`} onClick={() => { setBtn(e => !e) }}>Add new user</div> */}
                    {/* {
                        btn
                            ?
                            <div className={`setting-2-2 ${darkname}`} onClick={() => { onAdduser() }}><FontAwesomeIcon icon={faPlus} /></div>
                            :
                            ""
                    } */}
                </div>
                <div className='setting-3'>
                    <div className={`table-head ${darkname}`}>
                        <div>Name</div>
                        <div>Email</div>
                        <div>Password</div>
                        {/* <div>Country</div> */}
                        <div>Mobile</div>
                        <div>Level</div>
                    </div>
                    <div className='table-body'>
                        <div className={`table-body-tr td ${darkname}`}>
                            <div className='td'><input value={name} onChange={onChangeName} className={`table-input ${darkname}`} /></div>
                            <div className='td'><input value={email} onChange={onChangeEmail} className={`table-input ${darkname}`} /></div>
                            <div className='td'><input value={password} onChange={onChangePassword} className={`table-input ${darkname}`} /></div>
                            <div className='td'>
                                <input value={mobile} onChange={onChangeMobile} className={`table-input ${darkname}`} /></div>
                            <div className='td'>
                                <div className={`member-1-select border ${darkname}`} onClick={() => setSelect(e => !e)}>
                                    <div className={`member-1-select-1 ${darkname}`}>{selectValue}</div>
                                    {
                                        select
                                            ?
                                            <FontAwesomeIcon icon={faSortUp} />
                                            :
                                            <FontAwesomeIcon icon={faSortDown} />
                                    }
                                    {
                                        select
                                            ? <>
                                                <div className='modal' onClick={() => setSelect(true)}></div>
                                                <div className={`member-1-select-box ${darkname}`}>
                                                    <div onClick={() => { setSelectValue("SUPER"); setSelect(true) }}>SUPER</div>
                                                    <div onClick={() => { setSelectValue("MANAGER"); setSelect(true) }}>MANAGER</div>
                                                    <div onClick={() => { setSelectValue("GENERAL"); setSelect(true) }}>GENERAL</div>
                                                </div>
                                            </>
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='setting-4'>
                    <div className={`setting-4-btn ${darkname}`} onClick={() => AddNewUSer()}>Confirm</div>
                </div>
            </div>
        </div>
    )
}


function TradingTypeItem({ item, darkname, setReload, setPage }) {
    const [lastLogin, setLastLogin] = useState("")
    const [lastLogout, setLastLogout] = useState("")
    const [registdate, setRegistdate] = useState(false)
    const [selectStatus, setSelectStatus] = useState(false)
    const [selectStatusValue, setSelectStatusValue] = useState(item.status)
    const [selectLevel, setSelectLevel] = useState(false)
    const [selectLevelValue, setSelectLevelValue] = useState(item.authority == 0 ? "SUPER" : item.authority == 1 ? "MANAGER" : "GENERAL")

    useEffect(() => {
        if (item.registration_time) {
            let tmpdate = item.registration_time.split('T')
            let tmpdate1 = tmpdate[1].split('.000Z')
            let formattedDate = `${tmpdate[0]} ${tmpdate1[0]}`;
            setRegistdate(formattedDate)
        } else {
            setRegistdate('-')
        }

        if (item.last_login_time) {
            let tmpdate = item.last_login_time.split('T')
            let tmpdate1 = tmpdate[1].split('.000Z')
            let formattedDate = `${tmpdate[0]} ${tmpdate1[0]}`;
            setLastLogin(formattedDate)
        } else {
            setLastLogin('-')
        }

        if (item.last_logout_time) {
            let tmpdate = item.last_logout_time.split('T')
            let tmpdate1 = tmpdate[1].split('.000Z')
            let formattedDate = `${tmpdate[0]} ${tmpdate1[0]}`;
            setLastLogout(formattedDate)
        } else {
            setLastLogout('-')
        }

        setSelectStatusValue(item.status)
        setSelectLevelValue(item.authority == 0 ? "SUPER" : item.authority == 1 ? "MANAGER" : "GENERAL")
    }, [item])

    const onChangeStatus = async (e) => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get('https://api.admin.bitket.io/api/v1/admin/manager/status', {
                params: {
                    id: item.id,
                    status: e
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                setReload(e => !e)
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const onChangeAuthority = async (e) => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get('https://api.admin.bitket.io/api/v1/admin/manager/authority', {
                params: {
                    id: item.id,
                    authority: e
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                setReload(e => !e)
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const onDelete = async () => {
        if (window.confirm("Do you really want to delete?")) {
            const objString = localStorage.getItem('token');
            if (objString != null) {
                const obj = JSON.parse(objString);
                let token = obj.value;

                await axios.get(`https://api.admin.bitket.io/api/v1/admin/manager/delete`, {
                    params: {
                        id : item.id
                    },
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then((response) => {
                    setReload(e => !e)
                    setPage(1)
                }).catch(error => {
                    console.error(error);
                });
            }
        }
    }

    return (
        <div className={`table-body-tr ${darkname}`}>
            <div className='td'>{item.name}</div>
            <div className='td'>{item.email}</div>
            <div className='td'>{item.mobile}</div>
            <div className='td'>{registdate}</div>
            <div className='td'>{lastLogin}</div>
            <div className='td'>{lastLogout}</div>
            <div className='td'>
                <div className={`member-1-select border ${darkname}`} onClick={() => setSelectStatus(e => !e)}>
                    <div className={`member-1-select-1 ${darkname}`}>{selectStatusValue}</div>
                    {
                        selectStatus
                            ?
                            <FontAwesomeIcon icon={faSortUp} />
                            :
                            <FontAwesomeIcon icon={faSortDown} />
                    }
                    {
                        selectStatus
                            ? <>
                                <div className='modal' onClick={() => setSelectStatus(true)}></div>
                                <div className={`member-1-select-box ${darkname}`}>
                                    <div onClick={() => { onChangeStatus("ACTIVE"); setSelectStatusValue("ACTIVE"); setSelectStatus(true) }}>ACTIVE</div>
                                    <div onClick={() => { onChangeStatus("INACTIVE"); setSelectStatusValue("INACTIVE"); setSelectStatus(true) }}>INACTIVE</div>
                                </div>
                            </>
                            :
                            ""
                    }
                </div>
            </div>
            <div className='td'>
                <div className={`member-1-select border ${darkname}`} onClick={() => setSelectLevel(e => !e)}>
                    <div className={`member-1-select-1 ${darkname}`}>{selectLevelValue}</div>
                    {
                        selectLevel
                            ?
                            <FontAwesomeIcon icon={faSortUp} />
                            :
                            <FontAwesomeIcon icon={faSortDown} />
                    }
                    {
                        selectLevel
                            ? <>
                                <div className='modal' onClick={() => setSelectLevel(true)}></div>
                                <div className={`member-1-select-box ${darkname}`}>
                                    <div onClick={() => { onChangeAuthority("SUPER"); setSelectLevelValue("SUPER"); setSelectLevel(true) }}>SUPER</div>
                                    <div onClick={() => { onChangeAuthority("MANAGER"); setSelectLevelValue("MANAGER"); setSelectLevel(true) }}>MANAGER</div>
                                    <div onClick={() => { onChangeAuthority("GENERAL"); setSelectLevelValue("GENERAL"); setSelectLevel(true) }}>GENERAL</div>
                                </div>
                            </>
                            :
                            ""
                    }
                </div>
            </div>
            <div className='td' style={{ width: "50%" }}>
                <div className={`withdrawitem-btn ${darkname}`} onClick={() => onDelete()}> Delete</div>
            </div>
        </div >
    )
}

function padZero(value) {
    return value < 10 ? `0${value}` : value;
}

const Excel = (excelData) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `Admin_Members`;

    const ws = XLSX.utils.aoa_to_sheet([
        ["Name", "Email", "Mobile", "Reg", "LastLogin", "LastLogout", "Status", "Level"]
    ]);
    excelData.map((data) => {
        XLSX.utils.sheet_add_aoa(
            ws,
            [
                [
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Name },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Email },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Mobile },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Reg },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.LastLogin },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.LastLogout },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Status },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Level }
                ]
            ],
            { origin: -1 }
        );
        ws['!cols'] =
            [
                { wpx: 100 },
                { wpx: 130 },
                { wpx: 100 },
                { wpx: 140 },
                { wpx: 140 },
                { wpx: 140 },
                { wpx: 100 },
                { wpx: 100 }
            ]

        return false;
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
};

export default Setting;