import './css/login.css'
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../store';

function Login() {
    const [id, setId] = useState("")
    const [password, setPassword] = useState("")
    const [eye, setEye] = useState(false)

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
            }
            else {
                dispatch(checkLogin(true));
                navigate('/dashboard')
            }
        }
        else {
            dispatch(checkLogin(false));
        }
    }, [])

    let dark = useSelector((state) => { return state.Darkmode.value });

    const darkname = dark ? 'dark' : "";

    const onChangeId = (e) => {
        setId(e.target.value)
    }

    const onChangePassword = (e) => {
        setPassword(e.target.value)
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onSubmit()
        }
    };

    const onSubmit = async () => {
        await axios.get('https://api.admin.bitket.io/api/v1/admin/check', {
            params: {
                email: id,
                password: password
            }
        }).then((response) => {
            if (response.data.code === 1) {
                navigate('/verification', {
                    state: {
                        id: id,
                        password: password
                    }
                })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    return (
        <div className={`login ${darkname}`}>
            <div className='login-box'>
                {
                    dark
                        ?
                        <img src='/img/logo-white.png' />
                        :
                        <img src='/img/logo-black.png' />
                }
                <div className={`login-box-1 ${darkname}`}>
                    <input type='text' value={id} onChange={onChangeId} placeholder='ID' />
                </div>
                <div className={`login-box-1 ${darkname}`}>
                    <input type={eye ? 'text' : 'password'} value={password} onChange={onChangePassword} onKeyDown={handleKeyPress} placeholder='PASSWORD' />
                    {
                        eye
                            ?
                            <FontAwesomeIcon icon={faEye} className='login-eye' onClick={() => setEye(eye => !eye)} />
                            :
                            <FontAwesomeIcon icon={faEyeSlash} className='login-eye' onClick={() => setEye(eye => !eye)} />
                    }
                </div>
                <div className='login-box-2'>
                    <div className={`login-box-2-1 ${darkname}`} onClick={() => onSubmit()}>Confirm</div>
                    <div className={`login-box-2-1 ${darkname}`} onClick={() => navigate('/passwordReset')}>Password reset</div>
                </div>
            </div>
        </div>
    )
}

export default Login;