import './css/main.css'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCircleXmark, faSortDown, faSortUp, faWindowMinimize, faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import Sidebar from '../Nav/sidebar';
import NewPage from './NewPage'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../store';
import { SyncLoader } from "react-spinners";
import { removeZeros, changeNum } from './common/function'

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

let filter_bn = []
let filter_pre_bn = []
let filter_okx = []
let filter_pre_okx = []
let check_click = false

function TradingType() {
    let dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, [])

    const location = useLocation();
    const [tab, setTab] = useState(location.state === null ? 0 : location.state.num)

    useEffect(() => {
        if (location.state === null) setTab(0)
        else setTab(location.state.num)
    }, [location])

    return (
        <div className='main'>
            <Sidebar page={tab + 3} />
            {
                tab === 0
                    ?
                    <Trade_Bn tab={tab} />
                    :
                    tab === 1
                    ?
                    <Trade_OKX tab={tab} />
                    :
                    <Trade_Bybit tab={tab} />
            }
        </div>
    )
}

function Trade_Bn({ tab }) {
    const [search, setSearch] = useState("")
    const [btn, setBtn] = useState(0)
    const [dateBtn, setDateBtn] = useState(0)
    const [select, setSelect] = useState(false)
    const [selectValue, setSelectValue] = useState("BITKET ID")
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [page, setPage] = useState(1)
    const [tradingData, setTradingData] = useState([])
    const [tradingList, setTradingList] = useState([])
    const [spinner, setSpinner] = useState(false)
    const [clickId, setClickId] = useState("")

    const [traders, setTraders] = useState(0)
    const [volume, setVolume] = useState(0)
    const [fee, setFee] = useState(0)

    const [pre_traders, setPre_Traders] = useState(0)
    const [pre_volume, setPre_Volume] = useState(0)
    const [pre_fee, setPre_Fee] = useState(0)

    let dark = useSelector((state) => { return state.Darkmode.value });
    let SelectType = useSelector((state) => { return state.SelectType.value });

    const darkname = dark ? 'dark' : "";

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
        check_click = false
    }

    useEffect(() => {
        check_click = false
        setPage(1)
        TradingData()
    }, [btn])

    const TradingData = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(`https://api.admin.bitket.io/api/v1/bn/trade-history?type=${btn === 0 ? "spot" : "futures"}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setTradingData(tmp)
                }
                setTimeout(() => {
                    setSpinner(false)
                }, 500)
            }).catch(error => {
                console.error(error);
            });
        }
    }

    useEffect(() => {
        onSpinner()
        setPage(1)
    }, [selectedDate, selectedDate1])

    useEffect(() => {
        let t = []
        let v = 0
        let f = 0
        let arr = []

        if (tradingData && tradingData.length > 0) {
            for (let i = 0; i < tradingData.length; i++) {
                if (Math.floor(changeTime(selectedDate) / 1000) * 1000 <= new Date(tradingData[i].fillTime).getTime() && new Date(tradingData[i].fillTime).getTime() <= Math.floor(changeTime(selectedDate1) / 1000) * 1000 + 86399999) {
                    arr.push(tradingData[i])
                }
            }

            if (check_click && selectValue == "BITKET ID" && clickId.length > 0) {
                filter_bn = arr.filter((p) => {
                    return String(p.uid) == clickId
                })
            }
            else if (selectValue == "BITKET ID" && search.length > 0) {
                filter_bn = arr.filter((p) => {
                    return String(p.uid).toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "EXUID" && search.length > 0) {
                filter_bn = arr.filter((p) => {
                    return p.bnId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "TXID" && search.length > 0) {
                filter_bn = arr.filter((p) => {
                    return p.txId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else {
                filter_bn = arr
            }

            for (let i = 0; i < filter_bn.length; i++) {
                if (!t.includes(filter_bn[i].uid)) t.push(filter_bn[i].uid)
                v += Number(filter_bn[i].volume)
                f += btn === 0 ? Math.abs(filter_bn[i].fee * (filter_bn[i].POSITION === "BUY" ? Number(filter_bn[i].price) : 1)) : Math.abs(filter_bn[i].fee)
            }
            setTradingList(filter_bn)
            setTraders(t.length)
            setVolume(v)
            setFee(f)
        }
    }, [selectedDate, selectedDate1, tradingData, search, clickId])

    useEffect(() => {
        let t = []
        let v = 0
        let f = 0
        let arr = []

        if (tradingData && tradingData.length > 0) {
            for (let i = 0; i < tradingData.length; i++) {
                if (Math.floor((changeTime(selectedDate) - (changeTime(selectedDate1) - changeTime(selectedDate)) - 86400000) / 1000) * 1000
                    <= new Date(tradingData[i].fillTime).getTime() && new Date(tradingData[i].fillTime).getTime() <= Math.floor(changeTime(selectedDate) / 1000) * 1000 - 1) {
                    arr.push(tradingData[i])
                }
            }

            if (selectValue == "BITKET ID" && search.length > 0) {
                filter_pre_bn = arr.filter((p) => {
                    return String(p.uid).toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "EXUID" && search.length > 0) {
                filter_pre_bn = arr.filter((p) => {
                    return p.bnId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "TXID" && search.length > 0) {
                filter_pre_bn = arr.filter((p) => {
                    return p.txId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else {
                filter_pre_bn = arr
            }

            for (let i = 0; i < filter_pre_bn.length; i++) {
                if (!t.includes(filter_pre_bn[i].uid)) t.push(filter_pre_bn[i].uid)
                v += Number(filter_pre_bn[i].volume)
                f += btn === 0 ? Math.abs(filter_pre_bn[i].fee * (filter_pre_bn[i].POSITION === "BUY" ? Number(filter_pre_bn[i].price) : 1)) : Math.abs(filter_pre_bn[i].fee)
            }

            setPre_Traders(t.length)
            setPre_Volume(v)
            setPre_Fee(f)
        }
    }, [selectedDate, selectedDate1, tradingData, search, clickId])

    const onSpinner = () => {
        setSpinner(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500)
    }

    const excelData = () => {
        let arr = []
        if (tradingList) {
            for (let i = 0; i < tradingList.length; i++) {
                let t = tradingList[i]
                let date = t.fillTime.split('T')
                arr.push({
                    "Time": date ? date[0] : t.fillTime,
                    "BITKET": t.uid,
                    "EXUID": t.exuid,
                    "Asset": t.asset,
                    "Side": t.POSITION,
                    "Volume": Number(t.volume).toFixed(5),
                    "Price": t.price,
                    "Quantity": t.quantity,
                    "Fee": btn === 0 ? (Math.abs(t.fee) * (t.POSITION === "BUY" ? Number(t.price) : 1)).toFixed(8) : Math.abs(t.fee).toFixed(8),
                    "Commission": btn === 0 ? (Math.abs(t.fee) * (t.POSITION === "BUY" ? Number(t.price) : 1) * 0.3).toFixed(8) : Math.abs(t.fee * 0.3).toFixed(8)
                })
            }
        }

        Excel(btn, arr, "Binance", SelectType)
    }

    let slice_data = tradingList.slice((page - 1) * 15, (page) * 15)

    return (
        <div className={`main-box ${darkname}`}>
            <div className={`withdraw-1 ${darkname}`}>
                <div style={{ width: "56px", fontWeight: "bold" }}>{tab === 0 ? "Binance" : tab === 1 ? "OKX" : "Bybit"}</div>
                <div className='member-1'>
                    <div className={`member-2-search ${darkname}`}>
                        <div className={`member-1-select ${darkname}`} onClick={() => setSelect(e => !e)}>
                            <div className={`member-1-select-1 ${darkname}`}>{selectValue}</div>
                            {
                                select
                                    ?
                                    <FontAwesomeIcon icon={faSortUp} />
                                    :
                                    <FontAwesomeIcon icon={faSortDown} />
                            }
                            {
                                select
                                    ? <>
                                        <div className='modal' onClick={() => setSelect(true)}></div>
                                        <div className={`member-1-select-box ${darkname}`}>
                                            <div onClick={() => { setSelectValue("BITKET ID"); setSelect(true) }}>BITKET ID</div>
                                            <div onClick={() => { setSelectValue("EXUID"); setSelect(true) }}>EXUID</div>
                                        </div>
                                    </>
                                    :
                                    ""
                            }
                        </div>
                        <FontAwesomeIcon icon={faWindowMinimize} className='line' />
                        <div className={`member-2-search-1 ${darkname}`}>
                            <input value={search} onChange={onChangeSearch} placeholder='Search' />
                            <FontAwesomeIcon icon={faMagnifyingGlass} className='member-1-search-ico-3' />
                            {
                                search && search.length > 0
                                    ?
                                    <FontAwesomeIcon icon={faCircleXmark} className='member-1-search-ico-2' onClick={() => { setSearch(""); check_click = false }} />
                                    :
                                    ""
                            }
                        </div>
                    </div>
                </div>
                <div className={`modal-3-1-left ${darkname}`}>
                    <div className={dateBtn === 0 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(0)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => { setSelectedDate(date); setSelectedDate1(date) }}
                        />
                    </div>
                    <div className={dateBtn === 1 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(1);
                        setSelectedDate(new Date((new Date()).getTime())); setSelectedDate1(new Date())
                    }}>Today</div>
                    <div className={dateBtn === 2 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(2);
                        setSelectedDate(new Date(new Date().getTime() - 604800000)); setSelectedDate1(new Date())
                    }}>7 Days</div>
                    <div className={dateBtn === 3 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(3);
                        setSelectedDate(new Date(new Date().getTime() - 2592000000)); setSelectedDate1(new Date())
                    }}>30 Days</div>
                    <div className={dateBtn === 4 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(4);
                        setSelectedDate(new Date(new Date().getTime() - 7776000000)); setSelectedDate1(new Date())
                    }}>90 Days</div>
                    <div className={dateBtn === 5 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(5)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                        />
                        <div>~</div>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={selectedDate} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate1}
                            onChange={(date) => setSelectedDate1(date)}
                        />
                    </div>
                </div>
            </div>
            <div className={`reward-box ${darkname}`}>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Traders</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum(traders)}</div>
                        <div className={`reward-box-1-txt-2 ${traders - pre_traders >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={traders - pre_traders >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(traders - pre_traders))}</span>
                        </div>
                    </div>
                </div>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Volume (USDT)</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum(volume.toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${volume - pre_volume >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={volume - pre_volume >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(volume - pre_volume).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
                {
                    SelectType === 0
                        ?
                        <>
                            <div className='reward-box-1'>
                                <div className='reward-box-1-tit'>Fee (USDT)</div>
                                <div className='reward-box-1-txt'>
                                    <div className='reward-box-1-txt-1'>{changeNum(fee.toFixed(4))}</div>
                                    <div className={`reward-box-1-txt-2 ${fee - pre_fee >= 0 ? "green" : "red"}`}>
                                        <FontAwesomeIcon icon={fee - pre_fee >= 0 ? faCaretUp : faCaretDown} style={{ paddingDown: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(fee - pre_fee).toFixed(4))}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='reward-box-1'>
                                <div className='reward-box-1-tit'>Commission (USDT)</div>
                                <div className='reward-box-1-txt'>
                                    <div className='reward-box-1-txt-1'>{changeNum((fee * (tab === 0 ? 0.3 : 0.6)).toFixed(4))}</div>
                                    <div className={`reward-box-1-txt-2 ${fee - pre_fee >= 0 ? "green" : "red"}`}>
                                        <FontAwesomeIcon icon={fee - pre_fee >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs((fee - pre_fee) * (tab === 0 ? 0.3 : 0.6)).toFixed(4))}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        ""
                }
            </div>
            <div className='tradingType-1'>
                <div className={btn === 0 ? `tradingType-1-1 on ${darkname}` : `tradingType-1-1 ${darkname}`} onClick={() => { setSpinner(btn === 1 ? true : false); setBtn(0) }}>Spot</div>
                <div className={btn === 1 ? `tradingType-1-1 on ${darkname}` : `tradingType-1-1 ${darkname}`} onClick={() => { setSpinner(btn === 0 ? true : false); setBtn(1) }}>Futures</div>
                <div className={`tradingType-1-2 ${darkname}`} onClick={() => excelData()}>Excel download</div>
            </div>
            <div className='tradingType-2'>
                <div className={`table-head ${darkname}`}>
                    <div>Time</div>
                    <div style={{width:"50%"}}>BITKET ID</div>
                    <div>EXUID</div>
                    <div>{btn === 0 ? "Asset" : "Symbol"}</div>
                    <div style={{width:"50%"}}>Side</div>
                    <div>Volume</div>
                    <div>Price</div>
                    <div>Quantity</div>
                    {
                        SelectType === 0
                            ?
                            <>
                                <div>Fee</div>
                                <div>Commission</div>
                            </>
                            :
                            ""
                    }
                </div>
                <div className='table-body' style={{ minHeight: "600px" }}>
                    {
                        spinner
                            ?
                            <div className='spinner-modal'>
                                <SyncLoader color="#F5AC1D" />
                            </div>
                            :
                            slice_data && slice_data.length === 0
                                ?
                                <div className='data-nofound'>
                                    <img src='/img/img_nofound.png'></img>
                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                    <p>NO RECORDS FOUND.</p>
                                </div>
                                :
                                <>
                                    {
                                        slice_data.map(function (a, i) {
                                            return (
                                                <TradingTypeItem key={i} item={a} darkname={darkname} btn={btn} setClickId={setClickId} setSearch={setSearch} />
                                            )
                                        })
                                    }
                                    <NewPage initialData={tradingList} page={page} setPage={setPage} maxFirstPage={15} />
                                </>
                    }
                </div>
            </div>
        </div>
    )
}

function Trade_OKX({ tab }) {
    const [search, setSearch] = useState("")
    const [btn, setBtn] = useState(0)
    const [dateBtn, setDateBtn] = useState(0)
    const [select, setSelect] = useState(false)
    const [selectValue, setSelectValue] = useState("BITKET ID")
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [page, setPage] = useState(1)
    const [tradingData, setTradingData] = useState([])
    const [tradingList, setTradingList] = useState([])
    const [spinner, setSpinner] = useState(false)
    const [clickId, setClickId] = useState("")

    const [traders, setTraders] = useState(0)
    const [volume, setVolume] = useState(0)
    const [fee, setFee] = useState(0)

    const [pre_traders, setPre_Traders] = useState(0)
    const [pre_volume, setPre_Volume] = useState(0)
    const [pre_fee, setPre_Fee] = useState(0)

    let dark = useSelector((state) => { return state.Darkmode.value });
    let SelectType = useSelector((state) => { return state.SelectType.value });

    const darkname = dark ? 'dark' : "";

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
        check_click = false
    }

    useEffect(() => {
        check_click = false
        setPage(1)
        TradingData()
    }, [btn])

    const TradingData = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(`https://api.admin.bitket.io/api/v1/okx/trade-history?type=${btn === 0 ? "spot" : "swap"}`, {
                params: {
                    start: changeTime(selectedDate),
                    end: changeTime(selectedDate1),
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setTradingData(tmp)
                }
                setTimeout(() => {
                    setSpinner(false)
                }, 500)
            }).catch(error => {
                console.error(error);
            });
        }
    }

    useEffect(() => {
        onSpinner()
        setPage(1)
    }, [selectedDate, selectedDate1, search])

    useEffect(() => {
        let t = []
        let v = 0
        let f = 0
        let arr = []

        if (tradingData && tradingData.length > 0) {
            for (let i = 0; i < tradingData.length; i++) {
                if (Math.floor(changeTime(selectedDate) / 1000) * 1000 <= new Date(tradingData[i].fillTime).getTime() && new Date(tradingData[i].fillTime).getTime() <= Math.floor(changeTime(selectedDate1) / 1000) * 1000 + 86399999) {
                    arr.push(tradingData[i])
                }
            }

            if (check_click && selectValue == "BITKET ID" && clickId.length > 0) {
                filter_okx = arr.filter((p) => {
                    return String(p.uid) == clickId
                })
            }
            else if (selectValue == "BITKET ID" && search.length > 0) {
                filter_okx = arr.filter((p) => {
                    return String(p.uid).toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "EXUID" && search.length > 0) {
                filter_okx = arr.filter((p) => {
                    return p.bnId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "TXID" && search.length > 0) {
                filter_okx = arr.filter((p) => {
                    return p.txId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else {
                filter_okx = arr
            }

            for (let i = 0; i < filter_okx.length; i++) {
                if (!t.includes(filter_okx[i].uid)) t.push(filter_okx[i].uid)
                v += Number(filter_okx[i].volume)
                f += btn === 0 ? Math.abs(Number(filter_okx[i].fee) * (filter_okx[i].POSITION === "BUY" ? Number(filter_okx[i].price) : 1)) : Math.abs(filter_okx[i].fee)
            }

            setTradingList(filter_okx)
            setTraders(t.length)
            setVolume(v)
            setFee(f)
        }
    }, [selectedDate, selectedDate1, tradingData, search, clickId])

    useEffect(() => {
        let t = []
        let v = 0
        let f = 0
        let arr = []

        if (tradingData && tradingData.length > 0) {
            for (let i = 0; i < tradingData.length; i++) {
                if (Math.floor((changeTime(selectedDate) - (changeTime(selectedDate1) - changeTime(selectedDate)) - 86400000) / 1000) * 1000
                    <= new Date(tradingData[i].fillTime).getTime() && new Date(tradingData[i].fillTime).getTime() <= Math.floor(changeTime(selectedDate) / 1000) * 1000 - 1) {
                    arr.push(tradingData[i])
                }
            }

            if (selectValue == "BITKET ID" && search.length > 0) {
                filter_pre_okx = arr.filter((p) => {
                    return String(p.uid).toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "EXUID" && search.length > 0) {
                filter_pre_okx = arr.filter((p) => {
                    return p.bnId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "TXID" && search.length > 0) {
                filter_pre_okx = arr.filter((p) => {
                    return p.txId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else {
                filter_pre_okx = arr
            }

            for (let i = 0; i < filter_pre_okx.length; i++) {
                if (!t.includes(filter_pre_okx[i].uid)) t.push(filter_pre_okx[i].uid)
                v += Number(filter_pre_okx[i].volume)
                f += btn === 0 ? Math.abs(Number(filter_pre_okx[i].fee) * (filter_pre_okx[i].POSITION === "BUY" ? Number(filter_pre_okx[i].price) : 1)) : Math.abs(filter_pre_okx[i].fee)
            }

            setPre_Traders(t.length)
            setPre_Volume(v)
            setPre_Fee(f)
        }
    }, [selectedDate, selectedDate1, tradingData, search])

    const onSpinner = () => {
        setSpinner(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500)
    }

    const excelData = () => {
        let arr = []
        if (tradingList) {
            for (let i = 0; i < tradingList.length; i++) {
                let t = tradingList[i]
                let date = t.fillTime.split('T')
                arr.push({
                    "Time": date ? date[0] : t.fillTime,
                    "BITKET": t.uid,
                    "EXUID": t.exuid,
                    "Asset": t.asset,
                    "Side": t.POSITION,
                    "Volume": Number(t.volume).toFixed(5),
                    "Price": t.price,
                    "Quantity": t.quantity,
                    "Fee": btn === 0 ? (Math.abs(t.fee) * (t.POSITION === "BUY" ? Number(t.price) : 1)).toFixed(8) : Math.abs(t.fee).toFixed(8),
                    "Commission": btn === 0 ? (Math.abs(t.fee) * (t.POSITION === "BUY" ? Number(t.price) : 1) * 0.6).toFixed(8) : Math.abs(t.fee * 0.6).toFixed(8)
                })
            }
        }

        Excel(btn, arr, "OKX", SelectType)
    }

    let slice_data = tradingList.slice((page - 1) * 15, (page) * 15)

    return (
        <div className={`main-box ${darkname}`}>
            <div className={`withdraw-1 ${darkname}`}>
                <div style={{ width: "56px", fontWeight: "bold" }}>{tab === 0 ? "Binance" : tab === 1 ? "OKX" : "Bybit"}</div>
                <div className='member-1'>
                    <div className={`member-2-search ${darkname}`}>
                        <div className={`member-1-select ${darkname}`} onClick={() => setSelect(e => !e)}>
                            <div className={`member-1-select-1 ${darkname}`}>{selectValue}</div>
                            {
                                select
                                    ?
                                    <FontAwesomeIcon icon={faSortUp} />
                                    :
                                    <FontAwesomeIcon icon={faSortDown} />
                            }
                            {
                                select
                                    ? <>
                                        <div className='modal' onClick={() => setSelect(true)}></div>
                                        <div className={`member-1-select-box ${darkname}`}>
                                            <div onClick={() => { setSelectValue("BITKET ID"); setSelect(true) }}>BITKET ID</div>
                                            <div onClick={() => { setSelectValue("EXUID"); setSelect(true) }}>EXUID</div>
                                        </div>
                                    </>
                                    :
                                    ""
                            }
                        </div>
                        <FontAwesomeIcon icon={faWindowMinimize} className='line' />
                        <div className={`member-2-search-1 ${darkname}`}>
                            <input value={search} onChange={onChangeSearch} placeholder='Search' />
                            <FontAwesomeIcon icon={faMagnifyingGlass} className='member-1-search-ico-3' />
                            {
                                search && search.length > 0
                                    ?
                                    <FontAwesomeIcon icon={faCircleXmark} className='member-1-search-ico-2' onClick={() => { setSearch(""); check_click = false }} />
                                    :
                                    ""
                            }
                        </div>
                    </div>
                </div>
                <div className={`modal-3-1-left ${darkname}`}>
                    <div className={dateBtn === 0 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(0)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => { setSelectedDate(date); setSelectedDate1(date) }}
                        />
                    </div>
                    <div className={dateBtn === 1 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(1);
                        setSelectedDate(new Date((new Date()).getTime())); setSelectedDate1(new Date())
                    }}>Today</div>
                    <div className={dateBtn === 2 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(2);
                        setSelectedDate(new Date(new Date().getTime() - 604800000)); setSelectedDate1(new Date())
                    }}>7 Days</div>
                    <div className={dateBtn === 3 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(3);
                        setSelectedDate(new Date(new Date().getTime() - 2592000000)); setSelectedDate1(new Date())
                    }}>30 Days</div>
                    <div className={dateBtn === 4 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(4);
                        setSelectedDate(new Date(new Date().getTime() - 7776000000)); setSelectedDate1(new Date())
                    }}>90 Days</div>
                    <div className={dateBtn === 5 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(5)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                        />
                        <div>~</div>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={selectedDate} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate1}
                            onChange={(date) => setSelectedDate1(date)}
                        />
                    </div>
                </div>
            </div>
            <div className={`reward-box ${darkname}`}>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Traders</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum(traders)}</div>
                        <div className={`reward-box-1-txt-2 ${traders - pre_traders >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={traders - pre_traders >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(traders - pre_traders))}</span>
                        </div>
                    </div>
                </div>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Volume (USDT)</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum(volume.toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${volume - pre_volume >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={volume - pre_volume >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(volume - pre_volume).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
                {
                    SelectType === 0
                        ?
                        <>
                            <div className='reward-box-1'>
                                <div className='reward-box-1-tit'>Fee (USDT)</div>
                                <div className='reward-box-1-txt'>
                                    <div className='reward-box-1-txt-1'>{changeNum(fee.toFixed(4))}</div>
                                    <div className={`reward-box-1-txt-2 ${fee - pre_fee >= 0 ? "green" : "red"}`}>
                                        <FontAwesomeIcon icon={fee - pre_fee >= 0 ? faCaretUp : faCaretDown} style={{ paddingDown: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(fee - pre_fee).toFixed(4))}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='reward-box-1'>
                                <div className='reward-box-1-tit'>Commission (USDT)</div>
                                <div className='reward-box-1-txt'>
                                    <div className='reward-box-1-txt-1'>{changeNum((fee * (tab === 0 ? 0.3 : 0.6)).toFixed(4))}</div>
                                    <div className={`reward-box-1-txt-2 ${fee - pre_fee >= 0 ? "green" : "red"}`}>
                                        <FontAwesomeIcon icon={fee - pre_fee >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs((fee - pre_fee) * (tab === 0 ? 0.3 : 0.6)).toFixed(4))}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        ""
                }
            </div>
            <div className='tradingType-1'>
                <div className={btn === 0 ? `tradingType-1-1 on ${darkname}` : `tradingType-1-1 ${darkname}`} onClick={() => { setSpinner(btn === 1 ? true : false); setBtn(0) }}>Spot</div>
                <div className={btn === 1 ? `tradingType-1-1 on ${darkname}` : `tradingType-1-1 ${darkname}`} onClick={() => { setSpinner(btn === 0 ? true : false); setBtn(1) }}>Futures</div>
                <div className={`tradingType-1-2 ${darkname}`} onClick={() => excelData()}>Excel download</div>
            </div>
            <div className='tradingType-2'>
                <div className={`table-head ${darkname}`}>
                    <div>Time</div>
                    <div style={{width:"50%"}}>BITKET ID</div>
                    <div>EXUID</div>
                    <div>{btn === 0 ? "Asset" : "Symbol"}</div>
                    <div style={{width:"50%"}}>Side</div>
                    <div>Volume</div>
                    <div>Price</div>
                    <div>Quantity</div>
                    {
                        SelectType === 0
                            ?
                            <>
                                <div>Fee</div>
                                <div>Commission</div>
                            </>
                            :
                            ""
                    }
                </div>
                <div className='table-body' style={{ minHeight: "600px" }}>
                    {
                        spinner
                            ?
                            <div className='spinner-modal'>
                                <SyncLoader color="#F5AC1D" />
                            </div>
                            :
                            slice_data && slice_data.length === 0
                                ?
                                <div className='data-nofound'>
                                    <img src='/img/img_nofound.png'></img>
                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                    <p>NO RECORDS FOUND.</p>
                                </div>
                                :
                                <>
                                    {
                                        slice_data.map(function (a, i) {
                                            return (
                                                <TradingTypeItem_OKX key={i} item={a} darkname={darkname} btn={btn} setClickId={setClickId} setSearch={setSearch} />
                                            )
                                        })
                                    }
                                    <NewPage initialData={tradingList} page={page} setPage={setPage} maxFirstPage={15} />
                                </>
                    }
                </div>
            </div>
        </div>
    )
}

function Trade_Bybit({ tab }) {
    const [search, setSearch] = useState("")
    const [btn, setBtn] = useState(0)
    const [dateBtn, setDateBtn] = useState(0)
    const [select, setSelect] = useState(false)
    const [selectValue, setSelectValue] = useState("BITKET ID")
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [page, setPage] = useState(1)
    const [tradingData, setTradingData] = useState([])
    const [tradingList, setTradingList] = useState([])
    const [spinner, setSpinner] = useState(false)
    const [clickId, setClickId] = useState("")

    const [traders, setTraders] = useState(0)
    const [volume, setVolume] = useState(0)
    const [fee, setFee] = useState(0)

    const [pre_traders, setPre_Traders] = useState(0)
    const [pre_volume, setPre_Volume] = useState(0)
    const [pre_fee, setPre_Fee] = useState(0)

    let dark = useSelector((state) => { return state.Darkmode.value });
    let SelectType = useSelector((state) => { return state.SelectType.value });

    const darkname = dark ? 'dark' : "";

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
        check_click = false
    }

    useEffect(() => {
        check_click = false
        setPage(1)
        TradingData()
    }, [btn])

    const TradingData = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(`https://api.admin.bitket.io/api/v1/bybit/trade-history?type=${btn === 0 ? "spot" : "linear"}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setTradingData(tmp)
                }
                setTimeout(() => {
                    setSpinner(false)
                }, 500)
            }).catch(error => {
                console.error(error);
            });
        }
    }

    useEffect(() => {
        onSpinner()
        setPage(1)
    }, [selectedDate, selectedDate1])

    useEffect(() => {
        let t = []
        let v = 0
        let f = 0
        let arr = []

        if (tradingData && tradingData.length > 0) {
            for (let i = 0; i < tradingData.length; i++) {
                if (Math.floor(changeTime(selectedDate) / 1000) * 1000 <= new Date(tradingData[i].time).getTime() && new Date(tradingData[i].time).getTime() <= Math.floor(changeTime(selectedDate1) / 1000) * 1000 + 86399999) {
                    arr.push(tradingData[i])
                }
            }

            if (check_click && selectValue == "BITKET ID" && clickId.length > 0) {
                filter_bn = arr.filter((p) => {
                    return String(p.uid) == clickId
                })
            }
            else if (selectValue == "BITKET ID" && search.length > 0) {
                filter_bn = arr.filter((p) => {
                    return String(p.uid).toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "EXUID" && search.length > 0) {
                filter_bn = arr.filter((p) => {
                    return p.bybit_id.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "TXID" && search.length > 0) {
                filter_bn = arr.filter((p) => {
                    return p.txId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else {
                filter_bn = arr
            }

            for (let i = 0; i < filter_bn.length; i++) {
                if (!t.includes(filter_bn[i].uid)) t.push(filter_bn[i].uid)
                v += Number(filter_bn[i].volume)
                f += btn === 0 ? Math.abs(filter_bn[i].fee * (filter_bn[i].side === "Buy" ? Number(filter_bn[i].price) : 1)) : Math.abs(filter_bn[i].fee)
            }
            setTradingList(filter_bn)
            setTraders(t.length)
            setVolume(v)
            setFee(f)
        }
    }, [selectedDate, selectedDate1, tradingData, search, clickId])

    useEffect(() => {
        let t = []
        let v = 0
        let f = 0
        let arr = []

        if (tradingData && tradingData.length > 0) {
            for (let i = 0; i < tradingData.length; i++) {
                if (Math.floor((changeTime(selectedDate) - (changeTime(selectedDate1) - changeTime(selectedDate)) - 86400000) / 1000) * 1000
                    <= new Date(tradingData[i].time).getTime() && new Date(tradingData[i].time).getTime() <= Math.floor(changeTime(selectedDate) / 1000) * 1000 - 1) {
                    arr.push(tradingData[i])
                }
            }

            if (selectValue == "BITKET ID" && search.length > 0) {
                filter_pre_bn = arr.filter((p) => {
                    return String(p.uid).toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "EXUID" && search.length > 0) {
                filter_pre_bn = arr.filter((p) => {
                    return p.bybit_id.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "TXID" && search.length > 0) {
                filter_pre_bn = arr.filter((p) => {
                    return p.txId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else {
                filter_pre_bn = arr
            }

            for (let i = 0; i < filter_pre_bn.length; i++) {
                if (!t.includes(filter_pre_bn[i].uid)) t.push(filter_pre_bn[i].uid)
                v += Number(filter_pre_bn[i].volume)
                f += btn === 0 ? Math.abs(filter_pre_bn[i].fee * (filter_pre_bn[i].side === "Buy" ? Number(filter_pre_bn[i].price) : 1)) : Math.abs(filter_pre_bn[i].fee)
            }

            setPre_Traders(t.length)
            setPre_Volume(v)
            setPre_Fee(f)
        }
    }, [selectedDate, selectedDate1, tradingData, search, clickId])

    const onSpinner = () => {
        setSpinner(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500)
    }

    const excelData = () => {
        let arr = []
        if (tradingList) {
            for (let i = 0; i < tradingList.length; i++) {
                let t = tradingList[i]
                arr.push({
                    "Time": t.time,
                    "BITKET": t.uid,
                    "EXUID": t.bybit_id,
                    "Asset": t.symbol,
                    "Side": t.side,
                    "Volume": Number(t.volume).toFixed(5),
                    "Price": t.price,
                    "Quantity": t.qty,
                    "Fee": btn === 0 ? (Math.abs(t.fee) * (t.side === "Buy" ? Number(t.price) : 1)).toFixed(8) : Math.abs(t.fee).toFixed(8),
                    "Commission": btn === 0 ? (Math.abs(t.fee) * (t.side === "Buy" ? Number(t.price) : 1) * 0.3).toFixed(8) : Math.abs(t.fee * 0.3).toFixed(8)
                })
            }
        }

        Excel(btn, arr, "Bybit", SelectType)
    }

    let slice_data = tradingList.slice((page - 1) * 15, (page) * 15)

    return (
        <div className={`main-box ${darkname}`}>
            <div className={`withdraw-1 ${darkname}`}>
                <div style={{ width: "56px", fontWeight: "bold" }}>{tab === 0 ? "Binance" : tab === 1 ? "OKX" : "Bybit"}</div>
                <div className='member-1'>
                    <div className={`member-2-search ${darkname}`}>
                        <div className={`member-1-select ${darkname}`} onClick={() => setSelect(e => !e)}>
                            <div className={`member-1-select-1 ${darkname}`}>{selectValue}</div>
                            {
                                select
                                    ?
                                    <FontAwesomeIcon icon={faSortUp} />
                                    :
                                    <FontAwesomeIcon icon={faSortDown} />
                            }
                            {
                                select
                                    ? <>
                                        <div className='modal' onClick={() => setSelect(true)}></div>
                                        <div className={`member-1-select-box ${darkname}`}>
                                            <div onClick={() => { setSelectValue("BITKET ID"); setSelect(true) }}>BITKET ID</div>
                                            <div onClick={() => { setSelectValue("EXUID"); setSelect(true) }}>EXUID</div>
                                        </div>
                                    </>
                                    :
                                    ""
                            }
                        </div>
                        <FontAwesomeIcon icon={faWindowMinimize} className='line' />
                        <div className={`member-2-search-1 ${darkname}`}>
                            <input value={search} onChange={onChangeSearch} placeholder='Search' />
                            <FontAwesomeIcon icon={faMagnifyingGlass} className='member-1-search-ico-3' />
                            {
                                search && search.length > 0
                                    ?
                                    <FontAwesomeIcon icon={faCircleXmark} className='member-1-search-ico-2' onClick={() => { setSearch(""); check_click = false }} />
                                    :
                                    ""
                            }
                        </div>
                    </div>
                </div>
                <div className={`modal-3-1-left ${darkname}`}>
                    <div className={dateBtn === 0 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(0)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => { setSelectedDate(date); setSelectedDate1(date) }}
                        />
                    </div>
                    <div className={dateBtn === 1 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(1);
                        setSelectedDate(new Date((new Date()).getTime())); setSelectedDate1(new Date())
                    }}>Today</div>
                    <div className={dateBtn === 2 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(2);
                        setSelectedDate(new Date(new Date().getTime() - 604800000)); setSelectedDate1(new Date())
                    }}>7 Days</div>
                    <div className={dateBtn === 3 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(3);
                        setSelectedDate(new Date(new Date().getTime() - 2592000000)); setSelectedDate1(new Date())
                    }}>30 Days</div>
                    <div className={dateBtn === 4 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(4);
                        setSelectedDate(new Date(new Date().getTime() - 7776000000)); setSelectedDate1(new Date())
                    }}>90 Days</div>
                    <div className={dateBtn === 5 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(5)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                        />
                        <div>~</div>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={selectedDate} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate1}
                            onChange={(date) => setSelectedDate1(date)}
                        />
                    </div>
                </div>
            </div>
            <div className={`reward-box ${darkname}`}>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Traders</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum(traders)}</div>
                        <div className={`reward-box-1-txt-2 ${traders - pre_traders >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={traders - pre_traders >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(traders - pre_traders))}</span>
                        </div>
                    </div>
                </div>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Volume (USDT)</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum(volume.toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${volume - pre_volume >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={volume - pre_volume >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(volume - pre_volume).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
                {
                    SelectType === 0
                        ?
                        <>
                            <div className='reward-box-1'>
                                <div className='reward-box-1-tit'>Fee (USDT)</div>
                                <div className='reward-box-1-txt'>
                                    <div className='reward-box-1-txt-1'>{changeNum(fee.toFixed(4))}</div>
                                    <div className={`reward-box-1-txt-2 ${fee - pre_fee >= 0 ? "green" : "red"}`}>
                                        <FontAwesomeIcon icon={fee - pre_fee >= 0 ? faCaretUp : faCaretDown} style={{ paddingDown: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(fee - pre_fee).toFixed(4))}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='reward-box-1'>
                                <div className='reward-box-1-tit'>Commission (USDT)</div>
                                <div className='reward-box-1-txt'>
                                    <div className='reward-box-1-txt-1'>{changeNum((fee * (tab === 0 ? 0.3 : 0.6)).toFixed(4))}</div>
                                    <div className={`reward-box-1-txt-2 ${fee - pre_fee >= 0 ? "green" : "red"}`}>
                                        <FontAwesomeIcon icon={fee - pre_fee >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs((fee - pre_fee) * (tab === 0 ? 0.3 : 0.6)).toFixed(4))}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        ""
                }
            </div>
            <div className='tradingType-1'>
                <div className={btn === 0 ? `tradingType-1-1 on ${darkname}` : `tradingType-1-1 ${darkname}`} onClick={() => { setSpinner(btn === 1 ? true : false); setBtn(0) }}>Spot</div>
                <div className={btn === 1 ? `tradingType-1-1 on ${darkname}` : `tradingType-1-1 ${darkname}`} onClick={() => { setSpinner(btn === 0 ? true : false); setBtn(1) }}>Futures</div>
                <div className={`tradingType-1-2 ${darkname}`} onClick={() => excelData()}>Excel download</div>
            </div>
            <div className='tradingType-2'>
                <div className={`table-head ${darkname}`}>
                    <div>Time</div>
                    <div style={{width:"50%"}}>BITKET ID</div>
                    <div>EXUID</div>
                    <div>{btn === 0 ? "Asset" : "Symbol"}</div>
                    <div style={{width:"50%"}}>Side</div>
                    <div>Volume</div>
                    <div>Price</div>
                    <div>Quantity</div>
                    {
                        SelectType === 0
                            ?
                            <>
                                <div>Fee</div>
                                <div>Commission</div>
                            </>
                            :
                            ""
                    }
                </div>
                <div className='table-body' style={{ minHeight: "600px" }}>
                    {
                        spinner
                            ?
                            <div className='spinner-modal'>
                                <SyncLoader color="#F5AC1D" />
                            </div>
                            :
                            slice_data && slice_data.length === 0
                                ?
                                <div className='data-nofound'>
                                    <img src='/img/img_nofound.png'></img>
                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                    <p>NO RECORDS FOUND.</p>
                                </div>
                                :
                                <>
                                    {
                                        slice_data.map(function (a, i) {
                                            return (
                                                <TradingTypeItem_Bybit key={i} item={a} darkname={darkname} btn={btn} setClickId={setClickId} setSearch={setSearch} />
                                            )
                                        })
                                    }
                                    <NewPage initialData={tradingList} page={page} setPage={setPage} maxFirstPage={15} />
                                </>
                    }
                </div>
            </div>
        </div>
    )
}

function TradingTypeItem({ item, darkname, btn, setClickId, setSearch }) {
    let SelectType = useSelector((state) => { return state.SelectType.value });
    let arr = item.fillTime.split('T')
    let arr1 = arr[1].split('.000Z')

    return (
        <div className={`table-body-tr ${darkname}`}>
            <div className='td'>{arr && arr1 ? `${arr[0]} ${arr1[0]}` : item.fillTime}</div>
            <div className='onclick td' style={{width:"50%"}} onClick={() => { setClickId(`${item.uid}`); check_click = true; setSearch(`${item.uid}`) }}>{item.uid}</div>
            <div className='td'>{item.exuid}</div>
            <div className='td'>{item.asset}</div>
            <div className='td' style={{width:"50%"}}>{item.POSITION}</div>
            <div className='right td'>{changeNum(Number(item.volume).toFixed(5))}</div>
            <div className='right td'>{changeNum(removeZeros(item.price))}</div>
            <div className='right td'>{changeNum(removeZeros(item.quantity))}</div>
            {
                SelectType === 0
                    ?
                    <>
                        <div className='right td'>{btn === 0 ? (Math.abs(item.fee) * (item.POSITION === "BUY" ? Number(item.price) : 1)).toFixed(8) : Math.abs(item.fee).toFixed(8)}</div>
                        <div className='right td'>{btn === 0 ? (Math.abs(item.fee) * (item.POSITION === "BUY" ? Number(item.price) : 1) * 0.3).toFixed(8) : Math.abs(item.fee * 0.3).toFixed(8)}</div>
                    </>
                    :
                    ""
            }
        </div>
    )
}

function TradingTypeItem_OKX({ item, darkname, btn, setClickId, setSearch }) {
    let SelectType = useSelector((state) => { return state.SelectType.value });
    let arr = item.fillTime.split('T')
    let arr1 = arr[1].split('.000Z')

    return (
        <div className={`table-body-tr ${darkname}`}>
            <div className='td'>{arr && arr1 ? `${arr[0]} ${arr1[0]}` : item.fillTime}</div>
            <div className='onclick td' style={{width:"50%"}} onClick={() => { setClickId(`${item.uid}`); check_click = true; setSearch(`${item.uid}`) }}>{item.uid}</div>
            <div className='td'>{item.exuid}</div>
            <div className='td'>{item.asset}</div>
            <div className='td' style={{width:"50%"}}>{item.POSITION}</div>
            <div className='right td'>{changeNum(Number(item.volume).toFixed(5))}</div>
            <div className='right td'>{changeNum(removeZeros(item.price))}</div>
            <div className='right td'>{changeNum(removeZeros(item.quantity))}</div>
            {
                SelectType === 0
                    ?
                    <>
                        <div className='right td'>{btn === 0 ? (Math.abs(item.fee) * (item.POSITION === "BUY" ? Number(item.price) : 1)).toFixed(8) : Math.abs(item.fee).toFixed(8)}</div>
                        <div className='right td'>{btn === 0 ? (Math.abs(item.fee) * (item.POSITION === "BUY" ? Number(item.price) : 1) * 0.6).toFixed(8) : Math.abs(item.fee * 0.6).toFixed(8)}</div>
                    </>
                    :
                    ""
            }
        </div>
    )
}

function TradingTypeItem_Bybit({ item, darkname, btn, setClickId, setSearch }) {
    let SelectType = useSelector((state) => { return state.SelectType.value });

    return (
        <div className={`table-body-tr ${darkname}`}>
            <div className='td'>{item.time}</div>
            <div className='onclick td' style={{width:"50%"}} onClick={() => { setClickId(`${item.uid}`); check_click = true; setSearch(`${item.uid}`) }}>{item.uid}</div>
            <div className='td'>{item.bybit_id}</div>
            <div className='td'>{item.symbol}</div>
            <div className='td' style={{width:"50%"}}>{item.side}</div>
            <div className='right td'>{changeNum(Number(item.volume).toFixed(5))}</div>
            <div className='right td'>{changeNum(removeZeros(String(item.price)))}</div>
            <div className='right td'>{changeNum(removeZeros(String(item.qty)))}</div>
            {
                SelectType === 0
                    ?
                    <>
                        <div className='right td'>{btn === 0 ? (Math.abs(item.fee) * (item.side === "Buy" ? Number(item.price) : 1)).toFixed(8) : Math.abs(item.fee).toFixed(8)}</div>
                        <div className='right td'>{btn === 0 ? (Math.abs(item.fee) * (item.side === "Buy" ? Number(item.price) : 1) * 0.3).toFixed(8) : Math.abs(item.fee * 0.3).toFixed(8)}</div>
                    </>
                    :
                    ""
            }
        </div>
    )
}

function changeTime(d) {
    d.setUTCHours(0);
    d.setUTCMinutes(0);
    d.setUTCSeconds(0);
    return d.getTime()
}

const Excel = (btn, excelData, exchange, SelectType) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `Trading_History_${exchange}_${btn === 0 ? "Spot" : "Futures"}`;

    const ws = XLSX.utils.aoa_to_sheet([
        ["Time", "BITKET ID", "EXUID", btn === 0 ? "Asset" : "Symbol", "Side", "Volume", "Price", "Quantity", "Fee", "Commission"]
    ]);
    excelData.map((data) => {
        XLSX.utils.sheet_add_aoa(
            ws,
            [
                [
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Time },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.BITKET },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.EXUID },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Asset },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Side },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.Volume },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.Price },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.Quantity },
                    SelectType === 0 ? { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.Fee } : "",
                    SelectType === 0 ? { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.Commission } : ""
                ]
            ],
            { origin: -1 }
        );
        ws['!cols'] = [
            { wpx: 150 },
            { wpx: 60 },
            { wpx: 130 },
            { wpx: 120 },
            { wpx: 60 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            SelectType === 0 ? { wpx: 100 } : "",
            SelectType === 0 ? { wpx: 100 } : "",
        ]
        return false;
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
};

function padZero(value) {
    return value < 10 ? `0${value}` : value;
}

export default TradingType;