import './css/main.css'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCircleXmark, faSortDown, faSortUp, faWindowMinimize, faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import Sidebar from '../Nav/sidebar';
import Page from './Page'
import NewPage from './NewPage'
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../store';
import { SyncLoader } from "react-spinners";
import { removeZeros, changeNum } from './common/function'

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

let filter_bn = []
let filter_pre_bn = []
let filter_okx = []
let filter_pre_okx = []

function Reward() {
    let dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, [])

    const location = useLocation();
    const [tab, setTab] = useState(location.state === null ? 0 : location.state.num)

    useEffect(() => {
        if (location.state === null) setTab(0)
        else setTab(location.state.num)
    }, [location])

    return (
        <div className='main'>
            <Sidebar page={tab + 6} />
            {
                tab === 0
                    ?
                    <Trade_Bn tab={tab} />
                    :
                    tab === 1
                    ?
                    <Trade_OKX tab={tab} />
                    :
                    <Trade_Bybit tab={tab} />
            }
        </div>
    )
}

function Trade_Bn({ tab }) {
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [btn, setBtn] = useState(0)
    const [dateBtn, setDateBtn] = useState(0)
    const [select, setSelect] = useState(false)
    const [selectValue, setSelectValue] = useState("BITKET ID")
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [reload, setReload] = useState(false)
    const [rewardData, setRewardData] = useState([])
    const [rewardData_bn, setRewardData_bn] = useState([])
    const [spinner, setSpinner] = useState(false)

    const [traders, setTraders] = useState(0)
    const [volume, setVolume] = useState(0)
    const [fee, setFee] = useState(0)

    const [pre_traders, setPre_Traders] = useState(0)
    const [pre_volume, setPre_Volume] = useState(0)
    const [pre_fee, setPre_Fee] = useState(0)

    let dark = useSelector((state) => { return state.Darkmode.value });
    let SelectType = useSelector((state) => { return state.SelectType.value });

    const darkname = dark ? 'dark' : "";

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
    }

    useEffect(() => {
        setSpinner(true)
        setPage(1)
        if (btn === 0) RewardData_binance_spot()
        else RewardData_binance_futures()
    }, [selectedDate, selectedDate1, reload, btn])

    const RewardData_binance_futures = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(`https://api.admin.bitket.io/api/v1/bn/futures/settlement`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setRewardData_bn(tmp)
                }
                setTimeout(() => {
                    setSpinner(false)
                }, 500)
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const RewardData_binance_spot = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(`https://api.admin.bitket.io/api/v1/bn/spot/settlement`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setRewardData_bn(tmp)
                }
                setTimeout(() => {
                    setSpinner(false)
                }, 500)
            }).catch(error => {
                console.error(error);
            });
        }
    }

    useEffect(() => {
        let t = []
        let v = 0
        let f = 0
        let arr = []
        if (rewardData_bn) {
            for (let i = 0; i < rewardData_bn.length; i++) {
                if (Math.floor(changeTime(selectedDate) / 1000) * 1000 <= new Date(rewardData_bn[i].date).getTime() && new Date(rewardData_bn[i].date).getTime() <= Math.floor(changeTime(selectedDate1) / 1000) * 1000 + 86399999) {
                    arr.push(rewardData_bn[i])
                }
            }

            if (selectValue == "BITKET ID" && search.length > 0) {
                filter_bn = arr.filter((p) => {
                    return String(p.uid).toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "EXUID" && search.length > 0) {
                filter_bn = arr.filter((p) => {
                    return p.bnId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "TXID" && search.length > 0) {
                filter_bn = arr.filter((p) => {
                    return p.txId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else {
                filter_bn = arr
            }

            for (let i = 0; i < filter_bn.length; i++) {
                if (!t.includes(filter_bn[i].uid)) t.push(filter_bn[i].uid)
                v += Number(filter_bn[i].volume)
                f += Math.abs(filter_bn[i].fee)
            }

            setRewardData(filter_bn)
            setTraders(t.length)
            setVolume(v)
            setFee(f)
        }
    }, [rewardData_bn, search])

    useEffect(() => {
        let t = []
        let v = 0
        let f = 0
        let arr = []

        if (rewardData_bn) {
            for (let i = 0; i < rewardData_bn.length; i++) {
                if (Math.floor((changeTime(selectedDate) - (changeTime(selectedDate1) - changeTime(selectedDate)) - 86400000) / 1000) * 1000
                    <= new Date(rewardData_bn[i].date).getTime() && new Date(rewardData_bn[i].date).getTime() <= Math.floor(changeTime(selectedDate) / 1000) * 1000 - 1) {
                    arr.push(rewardData_bn[i])
                }
            }

            if (selectValue == "BITKET ID" && search.length > 0) {
                filter_pre_bn = arr.filter((p) => {
                    return String(p.uid).toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "EXUID" && search.length > 0) {
                filter_pre_bn = arr.filter((p) => {
                    return p.bnId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "TXID" && search.length > 0) {
                filter_pre_bn = arr.filter((p) => {
                    return p.txId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else {
                filter_pre_bn = arr
            }

            for (let i = 0; i < filter_pre_bn.length; i++) {
                if (!t.includes(filter_pre_bn[i].uid)) t.push(filter_pre_bn[i].uid)
                v += Number(filter_pre_bn[i].volume)
                f += Math.abs(filter_pre_bn[i].fee)
            }

            setPre_Traders(t.length)
            setPre_Volume(v)
            setPre_Fee(f)
        }
    }, [rewardData_bn, search])


    const RewardSettleAllData = () => {
        let arr = []
        if (rewardData) {
            for (let i = 0; i < rewardData.length; i++) {
                if (rewardData[i].settlement == "0")
                    arr.push(rewardData[i].seq)
            }

        }

        return arr;
    }

    const RewardSettleAll = async (e) => {
        if (window.confirm("Are you sure you want to All settle?") === true) {
            const objString = localStorage.getItem('token');
            if (objString != null) {
                const obj = JSON.parse(objString);
                let token = obj.value;

                let data = RewardSettleAllData()

                await axios.post(`https://api.admin.bitket.io/api/v1/bn/settlement`, {
                    rewardId: data,
                    status: e
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then((response) => {
                    setReload(e => !e)
                }).catch(error => {
                    console.error(error);
                });
            }
        }
    }

    const onSpinner = () => {
        setSpinner(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500)
    }

    const excelData = () => {
        let arr = []
        if (rewardData) {
            for (let i = 0; i < rewardData.length; i++) {
                let t = rewardData[i]
                let date = t.date.split('T')
                if (btn === 0) {
                    arr.push({
                        "BITKET": t.uid,
                        "EXUID": t.bnId,
                        "Asset": t.asset,
                        "Reward": t.selferral,
                        "TXID": t.txId ? t.txId : "-"
                    })
                }
                else {
                    arr.push({
                        "Date": date ? date[0] : t.date,
                        "BITKET": t.uid,
                        "EXUID": t.bnId,
                        "Master": t.referral ? t.referral : "-",
                        "SelfPercent": t.selferralPercent,
                        "Volume": Number(t.volume).toFixed(5),
                        "PNL": Number(t.pnl).toFixed(8),
                        "Fee": Number(t.fee).toFixed(8),
                        "Self": Number(t.selferral).toFixed(8),
                        "TXID": t.txId ? t.txId : "-"
                    })
                }
            }
        }

        Excel(btn, arr, "Binance", SelectType)
    }

    let slice_data = rewardData.slice((page - 1) * 15, (page) * 15)

    return (
        <div className={`main-box ${darkname}`}>
            <div className={`withdraw-1 ${darkname}`}>
                <div style={{ width: "56px", fontWeight: "bold" }}>{tab === 0 ? "Binance" : tab === 1 ? "OKX" : "Bybit"}</div>
                <div className={`member-2-search ${darkname}`}>
                    <div className={`member-1-select ${darkname}`} onClick={() => setSelect(e => !e)}>
                        <div className={`member-1-select-1 ${darkname}`}>{selectValue}</div>
                        {
                            select
                                ?
                                <FontAwesomeIcon icon={faSortUp} />
                                :
                                <FontAwesomeIcon icon={faSortDown} />
                        }
                        {
                            select
                                ? <>
                                    <div className='modal' onClick={() => setSelect(true)}></div>
                                    <div className={`member-1-select-box ${darkname}`}>
                                        <div onClick={() => { setSelectValue("BITKET ID"); setSelect(true) }}>BITKET ID</div>
                                        <div onClick={() => { setSelectValue("EXUID"); setSelect(true) }}>EXUID</div>
                                        <div onClick={() => { setSelectValue("TXID"); setSelect(true) }}>TXID</div>
                                    </div>
                                </>
                                :
                                ""
                        }
                    </div>
                    <FontAwesomeIcon icon={faWindowMinimize} className='line' />
                    <div className={`member-2-search-1 ${darkname}`}>
                        <input value={search} onChange={onChangeSearch} placeholder='Search' />
                        <FontAwesomeIcon icon={faMagnifyingGlass} className='member-1-search-ico-3' />
                        {
                            search.length > 0
                                ?
                                <FontAwesomeIcon icon={faCircleXmark} className='member-1-search-ico-2' onClick={() => setSearch("")} />
                                :
                                ""
                        }
                    </div>
                </div>
                <div className={`modal-3-1-left ${darkname}`}>
                    <div className={dateBtn === 0 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(0)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => { setSelectedDate(date); setSelectedDate1(date) }}
                        />
                    </div>
                    <div className={dateBtn === 1 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(1);
                        setSelectedDate(new Date((new Date()).getTime() - 86400000)); setSelectedDate1(new Date((new Date()).getTime() - 86399999))
                    }}>Today</div>
                    <div className={dateBtn === 2 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(2);
                        setSelectedDate(new Date((new Date()).getTime() - 604800000)); setSelectedDate1(new Date())
                    }}>7 Days</div>
                    <div className={dateBtn === 3 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(3);
                        setSelectedDate(new Date((new Date()).getTime() - 2592000000)); setSelectedDate1(new Date())
                    }}>30 Days</div>
                    <div className={dateBtn === 4 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(4);
                        setSelectedDate(new Date((new Date()).getTime() - 7776000000)); setSelectedDate1(new Date())
                    }}>90 Days</div>
                    <div className={dateBtn === 5 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(5)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                        />
                        <div>~</div>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={selectedDate} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate1}
                            onChange={(date) => setSelectedDate1(date)}
                        />
                    </div>
                </div>
            </div>
            <div className={`reward-box ${darkname}`}>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Traders</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum(traders)}</div>
                        <div className={`reward-box-1-txt-2 ${traders - pre_traders >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={traders - pre_traders >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(traders - pre_traders))}</span>
                        </div>
                    </div>
                </div>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Volume (USDT)</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum(volume.toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${volume - pre_volume >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={volume - pre_volume >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(volume - pre_volume).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
                {
                    SelectType === 0
                        ?
                        <>
                            <div className='reward-box-1'>
                                <div className='reward-box-1-tit'>Fee (USDT)</div>
                                <div className='reward-box-1-txt'>
                                    <div className='reward-box-1-txt-1'>{changeNum(fee.toFixed(4))}</div>
                                    <div className={`reward-box-1-txt-2 ${fee - pre_fee >= 0 ? "green" : "red"}`}>
                                        <FontAwesomeIcon icon={fee - pre_fee >= 0 ? faCaretUp : faCaretDown} style={{ paddingDown: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(fee - pre_fee).toFixed(4))}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='reward-box-1'>
                                <div className='reward-box-1-tit'>Commission (USDT)</div>
                                <div className='reward-box-1-txt'>
                                    <div className='reward-box-1-txt-1'>{changeNum((fee * (tab === 0 ? 0.3 : 0.6)).toFixed(4))}</div>
                                    <div className={`reward-box-1-txt-2 ${fee - pre_fee >= 0 ? "green" : "red"}`}>
                                        <FontAwesomeIcon icon={fee - pre_fee >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs((fee - pre_fee) * (tab === 0 ? 0.3 : 0.6)).toFixed(4))}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        ""
                }
            </div>
            <div className='tradingType-1'>
                <div className={btn === 0 ? `tradingType-1-1 on ${darkname}` : `tradingType-1-1 ${darkname}`} onClick={() => { setSpinner(btn === 1 ? true : false); setBtn(0) }}>Spot</div>
                <div className={btn === 1 ? `tradingType-1-1 on ${darkname}` : `tradingType-1-1 ${darkname}`} onClick={() => { setSpinner(btn === 0 ? true : false); setBtn(1) }}>Futures</div>
                <div className={`tradingType-1-2 ${darkname}`} onClick={() => excelData()}>Excel download</div>
            </div>
            <div className='tradingType-2'>
                {
                    btn === 0
                        ?
                        <div className={`table-head ${darkname}`}>
                            <div>BITKET ID</div>
                            <div>{tab === 0 ? "Binance" : tab === 1 ? "OKX" : "Bybit"} UID</div>
                            <div>Asset</div>
                            {
                                SelectType === 0
                                    ?
                                    <div>Reward</div>
                                    :
                                    ""
                            }
                            <div>TXID</div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
                                {
                                    dateBtn === 1
                                        ?
                                        <>
                                            <div className={`withdrawitem-btn ${darkname} width-40`} onClick={() => RewardSettleAll(0)}>All</div>
                                            <div className={`withdrawitem-btn ${darkname} width-40`} onClick={() => RewardSettleAll(1)}>Cancel</div>
                                        </>
                                        :
                                        <div className={`${darkname}`}>Settlement</div>
                                }
                            </div>
                        </div>
                        :
                        <div className={`table-head ${darkname}`}>
                            <div style={{ width: "70%" }}>Date</div>
                            <div style={{ width: "80%" }}>BITKET ID</div>
                            <div>EXUID</div>
                            <div style={{ width: "70%" }}>Master</div>
                            <div style={{ width: "50%" }}>Self %</div>
                            <div>Volume</div>
                            <div>PNL</div>
                            {
                                SelectType === 0
                                    ?
                                    <>
                                        <div>Fee</div>
                                        <div>Self</div>
                                        <div>Referral</div>
                                        <div>Total</div>
                                    </>
                                    :
                                    ""
                            }
                            <div>TXID</div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
                                {
                                    dateBtn === 1
                                        ?
                                        <>
                                            <div className={`withdrawitem-btn ${darkname} width-40`} onClick={() => RewardSettleAll(0)}>All</div>
                                            <div className={`withdrawitem-btn ${darkname} width-40`} onClick={() => RewardSettleAll(1)}>Cancel</div>
                                        </>
                                        :
                                        <div className={`${darkname}`}>Settlement</div>
                                }
                            </div>
                        </div>
                }
                <div className='table-body' style={{ minHeight: "600px" }}>
                    {
                        spinner
                            ?
                            <div className='spinner-modal'>
                                <SyncLoader color="#F5AC1D" />
                            </div>
                            :
                            slice_data && slice_data.length === 0
                                ?
                                <div className='data-nofound'>
                                    <img src='/img/img_nofound.png'></img>
                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                    <p>NO RECORDS FOUND.</p>
                                </div>
                                :
                                <>
                                    {
                                        slice_data.map(function (a, i) {
                                            return (
                                                <TradingTypeItem_Binance key={i} item={a} btn={btn} darkname={darkname} type={1} setReload={setReload} dateBtn={dateBtn} />
                                            )
                                        })
                                    }
                                    <NewPage initialData={rewardData} page={page} setPage={setPage} maxFirstPage={15} />
                                </>
                    }
                </div>
            </div>
        </div>
    )
}

function Trade_OKX({ tab }) {
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [btn, setBtn] = useState(0)
    const [dateBtn, setDateBtn] = useState(0)
    const [select, setSelect] = useState(false)
    const [selectValue, setSelectValue] = useState("BITKET ID")
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [reload, setReload] = useState(false)
    const [rewardData, setRewardData] = useState([])
    const [rewardData_okx, setRewardData_okx] = useState([])
    const [spinner, setSpinner] = useState(false)

    const [traders, setTraders] = useState(0)
    const [volume, setVolume] = useState(0)
    const [fee, setFee] = useState(0)

    const [pre_traders, setPre_Traders] = useState(0)
    const [pre_volume, setPre_Volume] = useState(0)
    const [pre_fee, setPre_Fee] = useState(0)

    let dark = useSelector((state) => { return state.Darkmode.value });
    let SelectType = useSelector((state) => { return state.SelectType.value });

    const darkname = dark ? 'dark' : "";

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
    }

    useEffect(() => {
        setSpinner(true)
        setPage(1)
        if (btn === 0) RewardData_okx_spot()
        else RewardData_okx()
    }, [selectedDate, selectedDate1, reload, btn])

    const RewardData_okx = async (p) => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(`https://api.admin.bitket.io/api/v1/okx/swap/settlement`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setRewardData_okx(tmp)
                }
                setTimeout(() => {
                    setSpinner(false)
                }, 500)
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const RewardData_okx_spot = async (p) => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(`https://api.admin.bitket.io/api/v1/okx/spot/settlement`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setRewardData_okx(tmp)
                }
                setTimeout(() => {
                    setSpinner(false)
                }, 500)
            }).catch(error => {
                console.error(error);
            });
        }
    }

    useEffect(() => {
        let t = []
        let v = 0
        let f = 0
        let arr = []

        if (rewardData_okx && rewardData_okx.length > 0) {
            for (let i = 0; i < rewardData_okx.length; i++) {
                if (Math.floor(changeTime(selectedDate) / 1000) * 1000 <= new Date(rewardData_okx[i].date).getTime() && new Date(rewardData_okx[i].date).getTime() <= Math.floor(changeTime(selectedDate1) / 1000) * 1000 + 86399999) {
                    arr.push(rewardData_okx[i])
                }
            }

            if (selectValue == "BITKET ID" && search.length > 0) {
                filter_okx = arr.filter((p) => {
                    return String(p.uid).toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "EXUID" && search.length > 0) {
                filter_okx = arr.filter((p) => {
                    return p.bnId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "TXID" && search.length > 0) {
                filter_okx = arr.filter((p) => {
                    return p.txId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else {
                filter_okx = arr
            }


            for (let i = 0; i < filter_okx.length; i++) {
                if (!t.includes(filter_okx[i].uid)) t.push(filter_okx[i].uid)
                v += Number(filter_okx[i].volume)
                f += Math.abs(filter_okx[i].fee)
            }

            setRewardData(filter_okx)
            setTraders(t.length)
            setVolume(v)
            setFee(f)
        }
    }, [rewardData_okx, search])

    useEffect(() => {
        let t = []
        let v = 0
        let f = 0
        let arr = []

        if (rewardData_okx && rewardData_okx.length > 0) {
            for (let i = 0; i < rewardData_okx.length; i++) {
                if (Math.floor((changeTime(selectedDate) - (changeTime(selectedDate1) - changeTime(selectedDate)) - 86400000) / 1000) * 1000
                    <= new Date(rewardData_okx[i].date).getTime() && new Date(rewardData_okx[i].date).getTime() <= Math.floor(changeTime(selectedDate) / 1000) * 1000 - 1) {
                    arr.push(rewardData_okx[i])
                }
            }

            if (selectValue == "BITKET ID" && search.length > 0) {
                filter_pre_okx = arr.filter((p) => {
                    return String(p.uid).toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "EXUID" && search.length > 0) {
                filter_pre_okx = arr.filter((p) => {
                    return p.bnId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "TXID" && search.length > 0) {
                filter_pre_okx = arr.filter((p) => {
                    return p.txId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else {
                filter_pre_okx = arr
            }


            for (let i = 0; i < filter_pre_okx.length; i++) {
                if (!t.includes(filter_pre_okx[i].uid)) t.push(filter_pre_okx[i].uid)
                v += Number(filter_pre_okx[i].volume)
                f += Math.abs(filter_pre_okx[i].fee)
            }

            setPre_Traders(t.length)
            setPre_Volume(v)
            setPre_Fee(f)
        }
    }, [rewardData_okx, search])

    const RewardSettleAllData = () => {
        let arr = []

        if (rewardData_okx) {
            for (let i = 0; i < rewardData_okx.length; i++) {
                if (rewardData_okx[i].settlement == "0")
                    arr.push(rewardData_okx[i].seq)
            }

        }

        return arr;
    }

    const RewardSettleAll = async (e) => {
        if (window.confirm("Are you sure you want to All settle?") === true) {
            const objString = localStorage.getItem('token');
            if (objString != null) {
                const obj = JSON.parse(objString);
                let token = obj.value;

                let data = RewardSettleAllData()

                await axios.post(`https://api.admin.bitket.io/api/v1/okx/settlement`, {
                    rewardId: data,
                    status: e
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then((response) => {
                    setReload(e => !e)
                }).catch(error => {
                    console.error(error);
                });
            }
        }
    }

    const excelData = () => {
        let arr = []
        if (rewardData) {
            for (let i = 0; i < rewardData.length; i++) {
                let t = rewardData[i]
                let date = t.date.split('T')
                if (btn === 0) {
                    arr.push({
                        "BITKET": t.uid,
                        "EXUID": t.okxId,
                        "Asset": "USDT",
                        "Reward": t.selferral,
                        "TXID": t.txId ? t.txId : "-"
                    })
                }
                else {
                    arr.push({
                        "Date": date ? date[0] : t.date,
                        "BITKET": t.uid,
                        "EXUID": t.okxId,
                        "Master": t.referral ? t.referral : "-",
                        "SelfPercent": t.selferralPercent,
                        "Volume": Number(t.volume).toFixed(5),
                        "PNL": Number(t.pnl).toFixed(8),
                        "Fee": Number(t.fee).toFixed(8),
                        "Self": Number(t.selferral).toFixed(8),
                        "TXID": t.txId ? t.txId : "-"
                    })
                }
            }
        }

        Excel(btn, arr, "OKX", SelectType)
    }

    const onSpinner = () => {
        setSpinner(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500)
    }

    let slice_data = rewardData.slice((page - 1) * 15, (page) * 15)

    return (
        <div className={`main-box ${darkname}`}>
            <div className={`withdraw-1 ${darkname}`}>
                <div style={{ width: "56px", fontWeight: "bold" }}>{tab === 0 ? "Binance" : tab === 1 ? "OKX" : "Bybit"}</div>
                <div className={`member-2-search ${darkname}`}>
                    <div className={`member-1-select ${darkname}`} onClick={() => setSelect(e => !e)}>
                        <div className={`member-1-select-1 ${darkname}`}>{selectValue}</div>
                        {
                            select
                                ?
                                <FontAwesomeIcon icon={faSortUp} />
                                :
                                <FontAwesomeIcon icon={faSortDown} />
                        }
                        {
                            select
                                ? <>
                                    <div className='modal' onClick={() => setSelect(true)}></div>
                                    <div className={`member-1-select-box ${darkname}`}>
                                        <div onClick={() => { setSelectValue("BITKET ID"); setSelect(true) }}>BITKET ID</div>
                                        <div onClick={() => { setSelectValue("EXUID"); setSelect(true) }}>EXUID</div>
                                        <div onClick={() => { setSelectValue("TXID"); setSelect(true) }}>TXID</div>
                                    </div>
                                </>
                                :
                                ""
                        }
                    </div>
                    <FontAwesomeIcon icon={faWindowMinimize} className='line' />
                    <div className={`member-2-search-1 ${darkname}`}>
                        <input value={search} onChange={onChangeSearch} placeholder='Search' />
                        <FontAwesomeIcon icon={faMagnifyingGlass} className='member-1-search-ico-3' />
                        {
                            search.length > 0
                                ?
                                <FontAwesomeIcon icon={faCircleXmark} className='member-1-search-ico-2' onClick={() => setSearch("")} />
                                :
                                ""
                        }
                    </div>
                </div>
                <div className={`modal-3-1-left ${darkname}`}>
                    <div className={dateBtn === 0 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(0)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => { setSelectedDate(date); setSelectedDate1(date) }}
                        />
                    </div>
                    <div className={dateBtn === 1 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(1);
                        setSelectedDate(new Date((new Date()).getTime() - 86400000)); setSelectedDate1(new Date((new Date()).getTime() - 86399999))
                    }}>Today</div>
                    <div className={dateBtn === 2 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(2);
                        setSelectedDate(new Date((new Date()).getTime() - 604800000)); setSelectedDate1(new Date())
                    }}>7 Days</div>
                    <div className={dateBtn === 3 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(3);
                        setSelectedDate(new Date((new Date()).getTime() - 2592000000)); setSelectedDate1(new Date())
                    }}>30 Days</div>
                    <div className={dateBtn === 4 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(4);
                        setSelectedDate(new Date((new Date()).getTime() - 7776000000)); setSelectedDate1(new Date())
                    }}>90 Days</div>
                    <div className={dateBtn === 5 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(5)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                        />
                        <div>~</div>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={selectedDate} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate1}
                            onChange={(date) => setSelectedDate1(date)}
                        />
                    </div>
                </div>
            </div>
            <div className={`reward-box ${darkname}`}>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Traders</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum(traders)}</div>
                        <div className={`reward-box-1-txt-2 ${traders - pre_traders >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={traders - pre_traders >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(traders - pre_traders))}</span>
                        </div>
                    </div>
                </div>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Volume (USDT)</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum(volume.toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${volume - pre_volume >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={volume - pre_volume >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(volume - pre_volume).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
                {
                    SelectType === 0
                        ?
                        <>
                            <div className='reward-box-1'>
                                <div className='reward-box-1-tit'>Fee (USDT)</div>
                                <div className='reward-box-1-txt'>
                                    <div className='reward-box-1-txt-1'>{changeNum(fee.toFixed(4))}</div>
                                    <div className={`reward-box-1-txt-2 ${fee - pre_fee >= 0 ? "green" : "red"}`}>
                                        <FontAwesomeIcon icon={fee - pre_fee >= 0 ? faCaretUp : faCaretDown} style={{ paddingDown: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(fee - pre_fee).toFixed(4))}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='reward-box-1'>
                                <div className='reward-box-1-tit'>Commission (USDT)</div>
                                <div className='reward-box-1-txt'>
                                    <div className='reward-box-1-txt-1'>{changeNum((fee * (tab === 0 ? 0.3 : 0.6)).toFixed(4))}</div>
                                    <div className={`reward-box-1-txt-2 ${fee - pre_fee >= 0 ? "green" : "red"}`}>
                                        <FontAwesomeIcon icon={fee - pre_fee >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs((fee - pre_fee) * (tab === 0 ? 0.3 : 0.6)).toFixed(4))}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        ""
                }
            </div>
            <div className='tradingType-1'>
                <div className={btn === 0 ? `tradingType-1-1 on ${darkname}` : `tradingType-1-1 ${darkname}`} onClick={() => { setSpinner(btn === 1 ? true : false); setBtn(0) }}>Spot</div>
                <div className={btn === 1 ? `tradingType-1-1 on ${darkname}` : `tradingType-1-1 ${darkname}`} onClick={() => { setSpinner(btn === 0 ? true : false); setBtn(1) }}>Futures</div>
                <div className={`tradingType-1-2 ${darkname}`} onClick={() => excelData()}>Excel download</div>
            </div>
            <div className='tradingType-2'>
                {
                    btn === 0
                        ?
                        <div className={`table-head ${darkname}`}>
                            <div>BITKET ID</div>
                            <div>{tab === 0 ? "Binance" : tab === 1 ? "OKX" : "Bybit"} UID</div>
                            <div>Asset</div>
                            {
                                SelectType === 0
                                    ?
                                    <div>Reward</div>
                                    :
                                    ""
                            }
                            <div>TXID</div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
                                {
                                    dateBtn === 1
                                        ?
                                        <>
                                            <div className={`withdrawitem-btn ${darkname} width-40`} onClick={() => RewardSettleAll(0)}>All</div>
                                            <div className={`withdrawitem-btn ${darkname} width-40`} onClick={() => RewardSettleAll(1)}>Cancel</div>
                                        </>
                                        :
                                        <div className={`${darkname}`}>Settlement</div>
                                }
                            </div>
                        </div>
                        :
                        <div className={`table-head ${darkname}`}>
                            <div style={{ width: "70%" }}>Date</div>
                            <div style={{ width: "80%" }}>BITKET ID</div>
                            <div>EXUID</div>
                            <div style={{ width: "70%" }}>Master</div>
                            <div style={{ width: "50%" }}>Self %</div>
                            <div>Volume</div>
                            <div>PNL</div>
                            {
                                SelectType === 0
                                    ?
                                    <>
                                        <div>Fee</div>
                                        <div>Self</div>
                                        <div>Referral</div>
                                        <div>Total</div>
                                    </>
                                    :
                                    ""
                            }
                            <div>TXID</div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
                                {
                                    dateBtn === 1
                                        ?
                                        <>
                                            <div className={`withdrawitem-btn ${darkname} width-40`} onClick={() => RewardSettleAll(0)}>All</div>
                                            <div className={`withdrawitem-btn ${darkname} width-40`} onClick={() => RewardSettleAll(1)}>Cancel</div>
                                        </>
                                        :
                                        <div className={`${darkname}`}>Settlement</div>
                                }
                            </div>
                        </div>
                }
                <div className='table-body' style={{ minHeight: "600px" }}>
                    {
                        spinner
                            ?
                            <div className='spinner-modal'>
                                <SyncLoader color="#F5AC1D" />
                            </div>
                            :
                            slice_data && slice_data.length === 0
                                ?
                                <div className='data-nofound'>
                                    <img src='/img/img_nofound.png'></img>
                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                    <p>NO RECORDS FOUND.</p>
                                </div>
                                :
                                <>
                                    {
                                        slice_data.map(function (a, i) {
                                            return (
                                                <TradingTypeItem_OKX key={i} item={a} btn={btn} darkname={darkname} type={1} setReload={setReload} dateBtn={dateBtn} />
                                            )
                                        })
                                    }
                                    <NewPage initialData={rewardData} page={page} setPage={setPage} maxFirstPage={15} />
                                </>
                    }
                </div>
            </div>
        </div>
    )
}

function Trade_Bybit({ tab }) {
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [btn, setBtn] = useState(0)
    const [dateBtn, setDateBtn] = useState(0)
    const [select, setSelect] = useState(false)
    const [selectValue, setSelectValue] = useState("BITKET ID")
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [reload, setReload] = useState(false)
    const [rewardData, setRewardData] = useState([])
    const [rewardData_by, setRewardData_by] = useState([])
    const [spinner, setSpinner] = useState(false)

    const [traders, setTraders] = useState(0)
    const [volume, setVolume] = useState(0)
    const [fee, setFee] = useState(0)

    const [pre_traders, setPre_Traders] = useState(0)
    const [pre_volume, setPre_Volume] = useState(0)
    const [pre_fee, setPre_Fee] = useState(0)

    let dark = useSelector((state) => { return state.Darkmode.value });
    let SelectType = useSelector((state) => { return state.SelectType.value });

    const darkname = dark ? 'dark' : "";

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
    }

    useEffect(() => {
        setSpinner(true)
        setPage(1)
        if (btn === 0) RewardData_bybit_spot()
        else RewardData_bybit_futures()
    }, [selectedDate, selectedDate1, reload, btn])

    const RewardData_bybit_futures = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(`https://api.admin.bitket.io/api/v1/bybit/linear/settlement`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setRewardData_by(tmp)
                }
                setTimeout(() => {
                    setSpinner(false)
                }, 500)
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const RewardData_bybit_spot = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(`https://api.admin.bitket.io/api/v1/bybit/spot/settlement`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setRewardData_by(tmp)
                }
                setTimeout(() => {
                    setSpinner(false)
                }, 500)
            }).catch(error => {
                console.error(error);
            });
        }
    }

    useEffect(() => {
        let t = []
        let v = 0
        let f = 0
        let arr = []
        if (rewardData_by) {
            for (let i = 0; i < rewardData_by.length; i++) {
                if (Math.floor(changeTime(selectedDate) / 1000) * 1000 <= new Date(rewardData_by[i].date).getTime() && new Date(rewardData_by[i].date).getTime() <= Math.floor(changeTime(selectedDate1) / 1000) * 1000 + 86399999) {
                    arr.push(rewardData_by[i])
                }
            }

            if (selectValue == "BITKET ID" && search.length > 0) {
                filter_bn = arr.filter((p) => {
                    return String(p.uid).toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "EXUID" && search.length > 0) {
                filter_bn = arr.filter((p) => {
                    return p.bnId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "TXID" && search.length > 0) {
                filter_bn = arr.filter((p) => {
                    return p.txId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else {
                filter_bn = arr
            }

            for (let i = 0; i < filter_bn.length; i++) {
                if (!t.includes(filter_bn[i].uid)) t.push(filter_bn[i].uid)
                v += Number(filter_bn[i].volume)
                f += Math.abs(filter_bn[i].fee)
            }

            setRewardData(filter_bn)
            setTraders(t.length)
            setVolume(v)
            setFee(f)
        }
    }, [rewardData_by, search])

    useEffect(() => {
        let t = []
        let v = 0
        let f = 0
        let arr = []

        if (rewardData_by) {
            for (let i = 0; i < rewardData_by.length; i++) {
                if (Math.floor((changeTime(selectedDate) - (changeTime(selectedDate1) - changeTime(selectedDate)) - 86400000) / 1000) * 1000
                    <= new Date(rewardData_by[i].date).getTime() && new Date(rewardData_by[i].date).getTime() <= Math.floor(changeTime(selectedDate) / 1000) * 1000 - 1) {
                    arr.push(rewardData_by[i])
                }
            }

            if (selectValue == "BITKET ID" && search.length > 0) {
                filter_pre_bn = arr.filter((p) => {
                    return String(p.uid).toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "EXUID" && search.length > 0) {
                filter_pre_bn = arr.filter((p) => {
                    return p.bnId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "TXID" && search.length > 0) {
                filter_pre_bn = arr.filter((p) => {
                    return p.txId.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else {
                filter_pre_bn = arr
            }

            for (let i = 0; i < filter_pre_bn.length; i++) {
                if (!t.includes(filter_pre_bn[i].uid)) t.push(filter_pre_bn[i].uid)
                v += Number(filter_pre_bn[i].volume)
                f += Math.abs(filter_pre_bn[i].fee)
            }

            setPre_Traders(t.length)
            setPre_Volume(v)
            setPre_Fee(f)
        }
    }, [rewardData_by, search])


    const RewardSettleAllData = () => {
        let arr = []
        if (rewardData) {
            for (let i = 0; i < rewardData.length; i++) {
                if (rewardData[i].settlement == "0")
                    arr.push(rewardData[i].seq)
            }

        }

        return arr;
    }

    const RewardSettleAll = async (e) => {
        if (window.confirm("Are you sure you want to All settle?") === true) {
            const objString = localStorage.getItem('token');
            if (objString != null) {
                const obj = JSON.parse(objString);
                let token = obj.value;

                let data = RewardSettleAllData()

                await axios.post(`https://api.admin.bitket.io/api/v1/bybit/settlement`, {
                    rewardId: data,
                    status: e
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then((response) => {
                    setReload(e => !e)
                }).catch(error => {
                    console.error(error);
                });
            }
        }
    }

    const onSpinner = () => {
        setSpinner(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500)
    }

    const excelData = () => {
        let arr = []
        if (rewardData) {
            for (let i = 0; i < rewardData.length; i++) {
                let t = rewardData[i]
                let date = t.date.split('T')
                if (btn === 0) {
                    arr.push({
                        "BITKET": t.uid,
                        "EXUID": t.bnId,
                        "Asset": t.asset,
                        "Reward": t.selferral,
                        "TXID": t.txId ? t.txId : "-"
                    })
                }
                else {
                    arr.push({
                        "Date": date ? date[0] : t.date,
                        "BITKET": t.uid,
                        "EXUID": t.bnId,
                        "Master": t.referral ? t.referral : "-",
                        "SelfPercent": t.selferralPercent,
                        "Volume": Number(t.volume).toFixed(5),
                        "PNL": Number(t.pnl).toFixed(8),
                        "Fee": Number(t.fee).toFixed(8),
                        "Self": Number(t.selferral).toFixed(8),
                        "TXID": t.txId ? t.txId : "-"
                    })
                }
            }
        }

        Excel(btn, arr, "Binance", SelectType)
    }

    let slice_data = rewardData.slice((page - 1) * 15, (page) * 15)

    return (
        <div className={`main-box ${darkname}`}>
            <div className={`withdraw-1 ${darkname}`}>
                <div style={{ width: "56px", fontWeight: "bold" }}>{tab === 0 ? "Binance" : tab === 1 ? "OKX" : "Bybit"}</div>
                <div className={`member-2-search ${darkname}`}>
                    <div className={`member-1-select ${darkname}`} onClick={() => setSelect(e => !e)}>
                        <div className={`member-1-select-1 ${darkname}`}>{selectValue}</div>
                        {
                            select
                                ?
                                <FontAwesomeIcon icon={faSortUp} />
                                :
                                <FontAwesomeIcon icon={faSortDown} />
                        }
                        {
                            select
                                ? <>
                                    <div className='modal' onClick={() => setSelect(true)}></div>
                                    <div className={`member-1-select-box ${darkname}`}>
                                        <div onClick={() => { setSelectValue("BITKET ID"); setSelect(true) }}>BITKET ID</div>
                                        <div onClick={() => { setSelectValue("EXUID"); setSelect(true) }}>EXUID</div>
                                        <div onClick={() => { setSelectValue("TXID"); setSelect(true) }}>TXID</div>
                                    </div>
                                </>
                                :
                                ""
                        }
                    </div>
                    <FontAwesomeIcon icon={faWindowMinimize} className='line' />
                    <div className={`member-2-search-1 ${darkname}`}>
                        <input value={search} onChange={onChangeSearch} placeholder='Search' />
                        <FontAwesomeIcon icon={faMagnifyingGlass} className='member-1-search-ico-3' />
                        {
                            search.length > 0
                                ?
                                <FontAwesomeIcon icon={faCircleXmark} className='member-1-search-ico-2' onClick={() => setSearch("")} />
                                :
                                ""
                        }
                    </div>
                </div>
                <div className={`modal-3-1-left ${darkname}`}>
                    <div className={dateBtn === 0 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(0)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => { setSelectedDate(date); setSelectedDate1(date) }}
                        />
                    </div>
                    <div className={dateBtn === 1 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(1);
                        setSelectedDate(new Date((new Date()).getTime() - 86400000)); setSelectedDate1(new Date((new Date()).getTime() - 86399999))
                    }}>Today</div>
                    <div className={dateBtn === 2 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(2);
                        setSelectedDate(new Date((new Date()).getTime() - 604800000)); setSelectedDate1(new Date())
                    }}>7 Days</div>
                    <div className={dateBtn === 3 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(3);
                        setSelectedDate(new Date((new Date()).getTime() - 2592000000)); setSelectedDate1(new Date())
                    }}>30 Days</div>
                    <div className={dateBtn === 4 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(4);
                        setSelectedDate(new Date((new Date()).getTime() - 7776000000)); setSelectedDate1(new Date())
                    }}>90 Days</div>
                    <div className={dateBtn === 5 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(5)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                        />
                        <div>~</div>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={selectedDate} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate1}
                            onChange={(date) => setSelectedDate1(date)}
                        />
                    </div>
                </div>
            </div>
            <div className={`reward-box ${darkname}`}>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Traders</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum(traders)}</div>
                        <div className={`reward-box-1-txt-2 ${traders - pre_traders >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={traders - pre_traders >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(traders - pre_traders))}</span>
                        </div>
                    </div>
                </div>
                <div className='reward-box-1'>
                    <div className='reward-box-1-tit'>Volume (USDT)</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum(volume.toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${volume - pre_volume >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={volume - pre_volume >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(volume - pre_volume).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
                {
                    SelectType === 0
                        ?
                        <>
                            <div className='reward-box-1'>
                                <div className='reward-box-1-tit'>Fee (USDT)</div>
                                <div className='reward-box-1-txt'>
                                    <div className='reward-box-1-txt-1'>{changeNum(fee.toFixed(4))}</div>
                                    <div className={`reward-box-1-txt-2 ${fee - pre_fee >= 0 ? "green" : "red"}`}>
                                        <FontAwesomeIcon icon={fee - pre_fee >= 0 ? faCaretUp : faCaretDown} style={{ paddingDown: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(fee - pre_fee).toFixed(4))}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='reward-box-1'>
                                <div className='reward-box-1-tit'>Commission (USDT)</div>
                                <div className='reward-box-1-txt'>
                                    <div className='reward-box-1-txt-1'>{changeNum((fee * (tab === 0 ? 0.3 : 0.6)).toFixed(4))}</div>
                                    <div className={`reward-box-1-txt-2 ${fee - pre_fee >= 0 ? "green" : "red"}`}>
                                        <FontAwesomeIcon icon={fee - pre_fee >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs((fee - pre_fee) * (tab === 0 ? 0.3 : 0.6)).toFixed(4))}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        ""
                }
            </div>
            <div className='tradingType-1'>
                <div className={btn === 0 ? `tradingType-1-1 on ${darkname}` : `tradingType-1-1 ${darkname}`} onClick={() => { setSpinner(btn === 1 ? true : false); setBtn(0) }}>Spot</div>
                <div className={btn === 1 ? `tradingType-1-1 on ${darkname}` : `tradingType-1-1 ${darkname}`} onClick={() => { setSpinner(btn === 0 ? true : false); setBtn(1) }}>Futures</div>
                <div className={`tradingType-1-2 ${darkname}`} onClick={() => excelData()}>Excel download</div>
            </div>
            <div className='tradingType-2'>
                {
                    btn === 0
                        ?
                        <div className={`table-head ${darkname}`}>
                            <div>BITKET ID</div>
                            <div>{tab === 0 ? "Binance" : tab === 1 ? "OKX" : "Bybit"} UID</div>
                            <div>Asset</div>
                            {
                                SelectType === 0
                                    ?
                                    <div>Reward</div>
                                    :
                                    ""
                            }
                            <div>TXID</div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
                                {
                                    dateBtn === 1
                                        ?
                                        <>
                                            <div className={`withdrawitem-btn ${darkname} width-40`} onClick={() => RewardSettleAll(0)}>All</div>
                                            <div className={`withdrawitem-btn ${darkname} width-40`} onClick={() => RewardSettleAll(1)}>Cancel</div>
                                        </>
                                        :
                                        <div className={`${darkname}`}>Settlement</div>
                                }
                            </div>
                        </div>
                        :
                        <div className={`table-head ${darkname}`}>
                            <div style={{ width: "70%" }}>Date</div>
                            <div style={{ width: "80%" }}>BITKET ID</div>
                            <div>EXUID</div>
                            <div style={{ width: "70%" }}>Master</div>
                            <div style={{ width: "50%" }}>Self %</div>
                            <div>Volume</div>
                            <div>PNL</div>
                            {
                                SelectType === 0
                                    ?
                                    <>
                                        <div>Fee</div>
                                        <div>Self</div>
                                        <div>Referral</div>
                                        <div>Total</div>
                                    </>
                                    :
                                    ""
                            }
                            <div>TXID</div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
                                {
                                    dateBtn === 1
                                        ?
                                        <>
                                            <div className={`withdrawitem-btn ${darkname} width-40`} onClick={() => RewardSettleAll(0)}>All</div>
                                            <div className={`withdrawitem-btn ${darkname} width-40`} onClick={() => RewardSettleAll(1)}>Cancel</div>
                                        </>
                                        :
                                        <div className={`${darkname}`}>Settlement</div>
                                }
                            </div>
                        </div>
                }
                <div className='table-body' style={{ minHeight: "600px" }}>
                    {
                        spinner
                            ?
                            <div className='spinner-modal'>
                                <SyncLoader color="#F5AC1D" />
                            </div>
                            :
                            slice_data && slice_data.length === 0
                                ?
                                <div className='data-nofound'>
                                    <img src='/img/img_nofound.png'></img>
                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                    <p>NO RECORDS FOUND.</p>
                                </div>
                                :
                                <>
                                    {
                                        slice_data.map(function (a, i) {
                                            return (
                                                <TradingTypeItem_Bybit key={i} item={a} btn={btn} darkname={darkname} type={1} setReload={setReload} dateBtn={dateBtn} />
                                            )
                                        })
                                    }
                                    <NewPage initialData={rewardData} page={page} setPage={setPage} maxFirstPage={15} />
                                </>
                    }
                </div>
            </div>
        </div>
    )
}

function TradingTypeItem_Binance({ item, btn, darkname, type, setReload, dateBtn }) {
    let SelectType = useSelector((state) => { return state.SelectType.value });
    let arr = item.date.split('T')
    const RewardSettle = async (e) => {
        if (window.confirm("Are you sure you want to settle?") === true) {
            const objString = localStorage.getItem('token');
            if (objString != null) {
                const obj = JSON.parse(objString);
                let token = obj.value;

                await axios.post(`https://api.admin.bitket.io/api/v1/bn/settlement`, {
                    rewardId: [item.seq],
                    status: e
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then((response) => {
                    setReload(e => !e)
                }).catch(error => {
                    console.error(error);
                });
            }
        }
    }

    return (
        <div className={`table-body-tr ${darkname}`}>
            {
                btn === 0
                    ?
                    <>
                        <div className='td'>{item.uid}</div>
                        <div className='td'>{item.bnId}</div>
                        <div className='td'>{item.asset}</div>
                        {
                            SelectType === 0
                                ?
                                <div className='td'>{item.selferral}</div>
                                :
                                ""
                        }
                        <div className='td'>{item.txId ? item.txId : "-"}</div>
                        <div className='td'>
                            {
                                dateBtn === 1
                                    ?
                                    item.settlement == '0'
                                        ?
                                        item.status == '0'
                                            ?
                                            <div className={`withdrawitem-btn ${darkname}`} onClick={() => RewardSettle(1)}>Wait</div>
                                            :
                                            <div className={`withdrawitem-btn ${darkname}`} onClick={() => RewardSettle(0)}>Confirm</div>
                                        :
                                        <div className={`${darkname}`} style={{ border: "none" }}>-</div>
                                    :
                                    <div className={`${darkname}`} style={{ border: "none" }}>-</div>
                            }
                        </div>
                    </>
                    :
                    <>
                        <div className='td' style={{ width: "70%" }}>{arr ? arr[0] : item.date}</div>
                        <div className='td' style={{ width: "80%" }}>{item.uid}</div>
                        <div className='td'>{item.bnId}</div>
                        <div className='td' style={{ width: "70%" }}>{item.referral ? item.referral : "-"}</div>
                        <div className='td' style={{ width: "50%" }}>{Number(item.selferralPercent)}</div>
                        <div className='right td'>{changeNum(Number(item.volume).toFixed(5))}</div>
                        <div className='right td'>{changeNum(Number(item.pnl).toFixed(8))}</div>
                        {
                            SelectType === 0
                                ?
                                <>
                                    <div className='right td'>{changeNum(Number(item.fee).toFixed(8))}</div>
                                    <div className='right td'>{changeNum(Number(item.selferral).toFixed(8))}</div>
                                    <div className='right td'>{0}</div>
                                    <div className='right td'>{changeNum(Number(item.selferral).toFixed(8))}</div>
                                </>
                                :
                                ""
                        }
                        <div className='td'>{item.txId ? item.txId : "-"}</div>
                        <div className='td'>
                            {
                                dateBtn === 1
                                    ?
                                    item.settlement == '0'
                                        ?
                                        item.status == '0'
                                            ?
                                            <div className={`withdrawitem-btn ${darkname}`} onClick={() => RewardSettle(1)}>Wait</div>
                                            :
                                            <div className={`withdrawitem-btn ${darkname}`} onClick={() => RewardSettle(0)}>Confirm</div>
                                        :
                                        <div className={`${darkname}`} style={{ border: "none" }}>-</div>
                                    :
                                    <div className={`${darkname}`} style={{ border: "none" }}>-</div>
                            }
                        </div>
                    </>
            }
        </div>
    )
}

function TradingTypeItem_OKX({ item, btn, darkname, type, setReload, dateBtn }) {
    let SelectType = useSelector((state) => { return state.SelectType.value });
    let arr = item.date.split('T')

    const RewardSettle = async (e) => {
        if (window.confirm("Are you sure you want to settle?") === true) {
            const objString = localStorage.getItem('token');
            if (objString != null) {
                const obj = JSON.parse(objString);
                let token = obj.value;

                await axios.post(`https://api.admin.bitket.io/api/v1/okx/settlement`, {
                    rewardId: [item.seq],
                    status: e
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then((response) => {
                    setReload(e => !e)
                }).catch(error => {
                    console.error(error);
                });
            }
        }
    }

    return (
        <div className={`table-body-tr ${darkname}`}>
            {
                btn === 0
                    ?
                    <>
                        <div className='td'>{item.uid}</div>
                        <div className='td'>{item.okxId}</div>
                        <div className='td'>USDT</div>
                        {
                            SelectType === 0
                                ?
                                <div className='td'>{item.selferral}</div>
                                :
                                ""
                        }
                        <div className='td'>{item.txId ? item.txId : "-"}</div>
                        <div className='td'>
                            {
                                dateBtn === 1
                                    ?
                                    item.settlement == '0'
                                        ?
                                        item.status == '0'
                                            ?
                                            <div className={`withdrawitem-btn ${darkname}`} onClick={() => RewardSettle(1)}>Wait</div>
                                            :
                                            <div className={`withdrawitem-btn ${darkname}`} onClick={() => RewardSettle(0)}>Confirm</div>
                                        :
                                        <div className={`${darkname}`} style={{ border: "none" }}>-</div>
                                    :
                                    <div className={`${darkname}`} style={{ border: "none" }}>-</div>
                            }
                        </div>
                    </>
                    :
                    <>
                        <div className='td' style={{ width: "70%" }}>{arr ? arr[0] : item.date}</div>
                        <div className='td' style={{ width: "80%" }}>{item.uid}</div>
                        <div className='td'>{item.okxId}</div>
                        <div className='td' style={{ width: "70%" }}>{item.referral ? item.referral : "-"}</div>
                        <div className='td' style={{ width: "50%" }}>{Number(item.selferralPercent)}</div>
                        <div className='right td'>{changeNum(Number(item.volume).toFixed(5))}</div>
                        <div className='right td'>{changeNum(Number(item.pnl).toFixed(8))}</div>
                        {
                            SelectType === 0
                                ?
                                <>
                                    <div className='right td'>{changeNum(Number(item.fee).toFixed(8))}</div>
                                    <div className='right td'>{changeNum(Number(item.selferral).toFixed(8))}</div>
                                    <div className='right td'>{item.referral_reward && Number(item.referral_reward) != 0 ? changeNum(Number(item.referral_reward).toFixed(8)) : 0}</div>
                                    <div className='right td'>{changeNum((Number(item.selferral) + (item.referral_reward ? Number(item.referral_reward) : 0)).toFixed(8))}</div>
                                </>
                                :
                                ""
                        }
                        <div className='td'>{item.txId ? item.txId : "-"}</div>
                        <div className='td'>
                            {
                                dateBtn === 1
                                    ?
                                    item.settlement == '0'
                                        ?
                                        item.status == '0'
                                            ?
                                            <div className={`withdrawitem-btn ${darkname}`} onClick={() => RewardSettle(1)}>Wait</div>
                                            :
                                            <div className={`withdrawitem-btn ${darkname}`} onClick={() => RewardSettle(0)}>Confirm</div>
                                        :
                                        <div className={`${darkname}`} style={{ border: "none" }}>-</div>
                                    :
                                    <div className={`${darkname}`} style={{ border: "none" }}>-</div>
                            }
                        </div>
                    </>
            }
        </div>
    )
}

function TradingTypeItem_Bybit({ item, btn, darkname, type, setReload, dateBtn }) {
    let SelectType = useSelector((state) => { return state.SelectType.value });
    let arr = item.date.split('T')
    const RewardSettle = async (e) => {
        if (window.confirm("Are you sure you want to settle?") === true) {
            const objString = localStorage.getItem('token');
            if (objString != null) {
                const obj = JSON.parse(objString);
                let token = obj.value;

                await axios.post(`https://api.admin.bitket.io/api/v1/bybit/settlement`, {
                    rewardId: [item.seq],
                    status: e
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then((response) => {
                    setReload(e => !e)
                }).catch(error => {
                    console.error(error);
                });
            }
        }
    }

    return (
        <div className={`table-body-tr ${darkname}`}>
            {
                btn === 0
                    ?
                    <>
                        <div className='td'>{item.uid}</div>
                        <div className='td'>{item.exuid}</div>
                        <div className='td'>{item.asset}</div>
                        {
                            SelectType === 0
                                ?
                                <div className='td'>{item.selferral}</div>
                                :
                                ""
                        }
                        <div className='td'>{item.txId ? item.txId : "-"}</div>
                        <div className='td'>
                            {
                                dateBtn === 1
                                    ?
                                    item.settlement == '0'
                                        ?
                                        item.status == '0'
                                            ?
                                            <div className={`withdrawitem-btn ${darkname}`} onClick={() => RewardSettle(1)}>Wait</div>
                                            :
                                            <div className={`withdrawitem-btn ${darkname}`} onClick={() => RewardSettle(0)}>Confirm</div>
                                        :
                                        <div className={`${darkname}`} style={{ border: "none" }}>-</div>
                                    :
                                    <div className={`${darkname}`} style={{ border: "none" }}>-</div>
                            }
                        </div>
                    </>
                    :
                    <>
                        <div className='td' style={{ width: "70%" }}>{arr ? arr[0] : item.date}</div>
                        <div className='td' style={{ width: "80%" }}>{item.uid}</div>
                        <div className='td'>{item.exuid}</div>
                        <div className='td' style={{ width: "70%" }}>{item.referral ? item.referral : "-"}</div>
                        <div className='td' style={{ width: "50%" }}>{Number(item.selferral_percent)}</div>
                        <div className='right td'>{changeNum(Number(item.volume).toFixed(5))}</div>
                        <div className='right td'>{changeNum(Number(item.pnl).toFixed(8))}</div>
                        {
                            SelectType === 0
                                ?
                                <>
                                    <div className='right td'>{changeNum(Number(item.fee).toFixed(8))}</div>
                                    <div className='right td'>{changeNum(Number(item.selferral).toFixed(8))}</div>
                                    <div className='right td'>{0}</div>
                                    <div className='right td'>{changeNum(Number(item.selferral).toFixed(8))}</div>
                                </>
                                :
                                ""
                        }
                        <div className='td'>{item.txId ? item.txId : "-"}</div>
                        <div className='td'>
                            {
                                dateBtn === 1
                                    ?
                                    item.settlement == '0'
                                        ?
                                        item.status == '0'
                                            ?
                                            <div className={`withdrawitem-btn ${darkname}`} onClick={() => RewardSettle(1)}>Wait</div>
                                            :
                                            <div className={`withdrawitem-btn ${darkname}`} onClick={() => RewardSettle(0)}>Confirm</div>
                                        :
                                        <div className={`${darkname}`} style={{ border: "none" }}>-</div>
                                    :
                                    <div className={`${darkname}`} style={{ border: "none" }}>-</div>
                            }
                        </div>
                    </>
            }
        </div>
    )
}

function changeTime(d) {
    d.setUTCHours(0);
    d.setUTCMinutes(0);
    d.setUTCSeconds(0);
    return d.getTime()
}

const Excel = (btn, excelData, exchange, SelectType) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `Reward_History_${exchange}_${btn === 0 ? "Spot" : "Futures"}`;

    const ws = XLSX.utils.aoa_to_sheet([
        btn === 0
            ?
            ["BITKET ID", `${exchange} UID`, "Asset", "Reward", "TXID"]
            :
            ["Date", "BITKET ID", "EXUID", "Master", "Self %", "Volume", "PNL", "Fee", "Self", "TXID"]
    ]);
    excelData.map((data) => {
        XLSX.utils.sheet_add_aoa(
            ws,
            [
                btn === 0
                    ?
                    [
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.BITKET },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.EXUID },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Asset },
                        SelectType === 0 ? { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.Reward } : "",
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.TXID }
                    ]
                    : [
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Date },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.BITKET },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.EXUID },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Master },
                        { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.SelfPercent },
                        { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.Volume },
                        { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.PNL },
                        SelectType === 0 ? { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.Fee } : "",
                        SelectType === 0 ? { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.Self } : "",
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.TXID }
                    ]
            ],
            { origin: -1 }
        );
        ws['!cols'] =
            btn === 0
                ?
                [
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 }
                ]
                :
                [
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                ]

        return false;
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
};

export default Reward;