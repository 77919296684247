import './nav.css'
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function Footer() {
    let dark = useSelector((state) => { return state.Darkmode.value });
    const darkname = dark ? 'dark' : "";

    return (
        <div className={`footer ${darkname}`}>
            <div className='footer-box'>
                <div className='footer-box-1'>©2023. Bitket Inc. All Rights Reserved</div>
            </div>
        </div>
    )
}

export default Footer;