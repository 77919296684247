import '../css/main.css'
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCircleXmark, faSortDown, faSortUp, faWindowMinimize, faXmark, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import Sidebar from '../../Nav/sidebar';
import NewPage from '../NewPage'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../../store';
import { changeNum } from '../common/function';
import { SyncLoader } from "react-spinners";
import base64 from "base-64";
import moment from 'moment';
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import '../css/calendar.css'
import '../css/admin.css'

function Admin_Dashboard() {
    let dark = useSelector((state) => { return state.Darkmode.value });
    let login = useSelector((state) => { return state.login.value; });
    const darkname = dark ? 'dark' : "";

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());

    const [page, setPage] = useState(1)

    const [dayData, setDayData] = useState([])
    const [totalData, setTotalData] = useState([])
    const [newRef, setNewRef] = useState([])
    const [staySettlementCnt, setStaySettlementCnt] = useState([0, 0])
    const [staySettlement, setStaySettlement] = useState([])
    const [sum, setSum] = useState([0, 0])
    const [click_modal, setClick_modal] = useState(false)

    let dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, [])

    useEffect(() => {
        DayData()
        TotalData()
        MewRef()
        StaySettlement()
    }, [])

    const DayData = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/admin/get/settlement/today', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response.data
            if (tmp) {
                setDayData(tmp.tradeListResult)
                setSeries([Number(tmp?.tradeListResult[0]?.binance_income ?? 0), Number(tmp?.tradeListResult[0]?.okx_income ?? 0)])
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const TotalData = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/admin/get/settlement', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response.data
            if (tmp) {
                setTotalData(tmp.tradeListResult)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const MewRef = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/admin/get/user/recent', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response.data
            if (tmp) {
                setNewRef(tmp.tradeListResult)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const StaySettlement = async () => {
        await axios.get('https://api.referral.bitket.io/api/v1/broker/admin/get/settlement/today/confirm', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response.data
            if (tmp) {
                let b = 0
                let o = 0
                let arr = []

                for (let i = 0; i < tmp.tradeListResult.length; i++) {
                    let d = tmp.tradeListResult[i]
                    if (d.binance_confirm == "WAIT") b += 1
                    if (d.okx_confirm == "WAIT") o += 1
                    if (d.binance_confirm == "WAIT" || d.okx_confirm == "WAIT") arr.push(d)
                }
                setStaySettlementCnt([b, o])
                setStaySettlement(arr)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    useEffect(() => {
        if (totalData) {
            let bn = 0
            let okx = 0
            for (let i = 0; i < totalData.length; i++) {
                if (Math.floor(changeTime(selectedDate) / 1000) * 1000 <= new Date(totalData[i].date).getTime() && new Date(totalData[i].date).getTime() <= Math.floor(changeTime(selectedDate1) / 1000) * 1000 + 86399999) {

                    let tmp = totalData[i]
                    bn += Number(tmp.binance_income)
                    okx += Number(tmp.okx_income)
                }
            }
            setSum([bn, okx])
            setSeries1([bn, okx])
        }
    }, [totalData, selectedDate, selectedDate1])

    const today = new Date();
    const [date, setDate] = useState(today);
    const [activeStartDate, setActiveStartDate] = useState(new Date());
    const [series, setSeries] = useState([0, 0])
    const [series1, setSeries1] = useState([0, 0])

    const handleDateChange = (newDate) => {
        setDate(newDate);
    };

    const handleTodayClick = () => {
        const today = new Date();
        setActiveStartDate(today);
        setDate(today);
    };

    useEffect(() => {
        let objString = localStorage.getItem('token')
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(
                token.indexOf(".") + 1,
                token.lastIndexOf(".")
            );
            let dec = base64.decode(payload);
            let sub = JSON.parse(dec).sub
            // if (sub != "wodnjs7800@naver.com" && sub != "jb@hb.re.kr") {
            //     navigate('/')
            // }
        }
    }, [login])

    const options = {
        chart: {
            width: 380,
            type: 'donut',
        },
        labels: ['Binance', 'OKX'],
        colors: ['#fcd535', '#777777'],
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    };
    const options2 = {
        chart: {
            width: 380,
            type: 'donut',
        },
        labels: ['None'],
        colors: ['#777777'],
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    };

    let slice_data = newRef.slice((page - 1) * 4, (page) * 4)
    return (
        <div className='main'>
            <Sidebar page={18} />
            {
                click_modal
                    ?
                    <StayModal setClick_modal={setClick_modal} staySettlement={staySettlement} darkname={darkname} />
                    :
                    ""
            }
            <div className={`main-box ${darkname}`}>
                <div className='dashboard-box'>
                    <div className='admin-dashboard-1'>
                        <div className='admin-dashboard-1-1'>
                            <div className={`admin-dashboard-3 ${darkname}`}>
                                <div className="dashboard-3-1">
                                    <div className={`dashboard-3-1-1 ${darkname}`}>금일 정산</div>
                                </div>
                                <div className='admin-dashboard-3-1'>
                                    {
                                        series && series[0] === 0 && series[1] === 0
                                            ?
                                            <Chart options={options2} series={[1]} type='donut' width={300} />
                                            :
                                            <Chart options={options} series={series} type='donut' width={310} />
                                    }
                                    <div className='dashboard-4-3'>
                                        <div className={`table-head-dash ${darkname}`}>
                                            <div style={{ width: "50%" }}>거래소</div>
                                            <div>정산액(USDT)</div>
                                            <div style={{ width: "50%" }}>심사대기</div>
                                        </div>
                                        <div className='table-body' style={{ paddingBottom: "20px" }}>
                                            <div className={`table-body-tr-dash ${darkname}`}>
                                                <div style={{ width: "50%" }}>Binance</div>
                                                <div className='right'>{dayData && dayData[0] ? (Number(dayData[0].binance_income)).toFixed(8) : 0}</div>
                                                <div className='right stay-settlement' onClick={() => setClick_modal(true)}>{staySettlementCnt ? Number(staySettlementCnt[0]) : 0}</div>
                                            </div>
                                            <div className={`table-body-tr-dash ${darkname}`}>
                                                <div style={{ width: "50%" }}>OKX</div>
                                                <div className='right'>{dayData && dayData[0] ? (Number(dayData[0].okx_income)).toFixed(8) : 0}</div>
                                                <div className='right stay-settlement' onClick={() => setClick_modal(true)}>{staySettlementCnt ? Number(staySettlementCnt[1]) : 0}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className={`admin-dashboard-3 ${darkname}`}>
                                <div className="dashboard-3-1">
                                    <div className={`dashboard-3-1-1 ${darkname}`}>누적 정산</div>
                                    <div className={`modal-3-1-left-1 dashboard-3-1-2 flex ${darkname}`}>
                                        <DatePicker
                                            dateFormat='yyyy-MM-dd' // 날짜 형태
                                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                                            maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                                            selected={selectedDate}
                                            onChange={(date) => setSelectedDate(date)}
                                        />
                                        <div>~</div>
                                        <DatePicker
                                            dateFormat='yyyy-MM-dd' // 날짜 형태
                                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                            minDate={selectedDate} // minDate 이전 날짜 선택 불가
                                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                                            selected={selectedDate1}
                                            onChange={(date) => setSelectedDate1(date)}
                                        />
                                    </div>
                                </div>
                                <div className='admin-dashboard-3-1'>
                                    {
                                        series1 && series1[0] === 0 && series1[1] === 0
                                            ?
                                            <Chart options={options2} series={[1]} type='donut' width={300} />
                                            :
                                            <Chart options={options} series={series1} type='donut' width={310} />
                                    }
                                    <div className='dashboard-4-3'>
                                        <div className={`table-head-dash ${darkname}`}>
                                            <div style={{ width: "50%" }}>거래소</div>
                                            <div>누적 정산액(USDT)</div>
                                        </div>
                                        <div className='table-body' style={{ paddingBottom: "20px" }}>
                                            <div className={`table-body-tr-dash ${darkname}`}>
                                                <div style={{ width: "50%" }}>All</div>
                                                <div className='right'>{(sum[0] + sum[1]).toFixed(8)}</div>
                                            </div>
                                            <div className={`table-body-tr-dash ${darkname}`}>
                                                <div style={{ width: "50%" }}>Binance</div>
                                                <div className='right'>{sum[0].toFixed(8)}</div>
                                            </div>
                                            <div className={`table-body-tr-dash ${darkname}`}>
                                                <div style={{ width: "50%" }}>OKX</div>
                                                <div className='right'>{sum[1].toFixed(8)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`admin-dashboard-5 ${darkname}`}>
                                <div className="dashboard-4-1">
                                    <div className={`dashboard-3-1-1 ${darkname}`}>신규 레퍼럴</div>
                                </div>
                                <div className='dashboard-4-3'>
                                    <div className={`table-head-dash ${darkname}`}>
                                        <div style={{ width: "70%" }}>레퍼럴</div>
                                        <div style={{ width: "50%" }}>거래소 UID</div>
                                        <div style={{ width: "70%" }}>상위 레퍼럴</div>
                                        <div>등록일</div>
                                    </div>
                                    <div className='table-body' style={{ minHeight: "200px", paddingBottom: "0" }}>
                                        {
                                            slice_data && slice_data.length === 0
                                                ?
                                                <div className='data-nofound' style={{ margin: "20px 0" }}>
                                                    <img src='/img/img_nofound.png'></img>
                                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                                    <p>NO RECORDS FOUND.</p>
                                                </div>
                                                :
                                                <>
                                                    {
                                                        slice_data.map(function (a, i) {
                                                            return (
                                                                <DashboardRecentlyUser key={i} item={a} darkname={darkname} dark={dark} />
                                                            )
                                                        })
                                                    }
                                                    <NewPage initialData={newRef} page={page} setPage={setPage} maxFirstPage={4} />
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='StyledCalendarWrapper'>
                            <Calendar
                                value={date}
                                onChange={handleDateChange}
                                formatDay={(locale, date) => moment(date).format('D')}
                                formatYear={(locale, date) => moment(date).format('YYYY')}
                                formatMonthYear={(locale, date) => moment(date).format('YYYY. MM')}
                                calendarType="gregory"
                                showNeighboringMonth={false}
                                next2Label={null}
                                prev2Label={null}
                                minDetail="year"

                                // 오늘 날짜로 돌아오는 기능을 위해 필요한 옵션 설정
                                activeStartDate={activeStartDate === null ? undefined : activeStartDate}
                                onActiveStartDateChange={({ activeStartDate }) =>
                                    setActiveStartDate(activeStartDate)
                                }

                                // 오늘 날짜에 '오늘' 텍스트 삽입하고 출석한 날짜에 점 표시를 위한 설정
                                tileContent={({ date, view }) => {
                                    let html = [];
                                    if (view === "month") {
                                        for (let i = 0; i < totalData.length; i++) {
                                            let tmp = totalData[i]
                                            if (tmp.date.split('T')[0] === moment(date).format("YYYY-MM-DD")) {
                                                html.push(<div className='StyledEX'>
                                                    <div className='StyledEX-1'>
                                                        <div>Binance:</div>
                                                        <div>{Number(tmp.binance_income).toFixed(7)} USDT</div>
                                                    </div>
                                                    <div className='StyledEX-2'>
                                                        <div>OKX:</div>
                                                        <div>{Number(tmp.okx_income).toFixed(7)} USDT</div>
                                                    </div>
                                                </div>);
                                            }
                                        }
                                    }
                                    return <>{html}</>;
                                }}
                            />

                            {/* 오늘 버튼 추가 */}
                            <div className='StyledType'>
                                <div className='StyledType-1'>Binance</div>
                                <div className='StyledType-2'>OKX</div>
                            </div>
                            <div className='StyledDate' onClick={handleTodayClick}>오늘</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

function DashboardRecentlyUser({ item, darkname }) {
    let tmpdate = item.regist_date.split("T")
    let tmpdate1 = tmpdate[1].split(".000Z")
    return (
        <div className={`table-body-tr-dash ${darkname}`}>
            <div style={{ width: "70%" }}>{item.broker}</div>
            <div style={{ width: "50%" }}>{item.uid ?? '-'}</div>
            <div style={{ width: "70%" }}>{item.broker_master ?? '-'}</div>
            <div>{`${tmpdate[0]} ${tmpdate1[0]}`}</div>
        </div>
    )
}

function StayModal({ setClick_modal, staySettlement, darkname }) {
    const [page, setPage] = useState(1)
    const [spinner, setSpinner] = useState(false)
    let slice_data = staySettlement.slice((page - 1) * 5, (page) * 5)

    return (
        <div className='modal'>
            <div className={`modal-box`}>
                <div className={`modal-3`} style={{ width: "1350px" }}>
                    <div className='modal-1-tit'>정산 심사 대기</div>
                    <div className='modal-3-1' style={{ height: "auto" }}>
                        <div className='tradingType-2'>
                            <div className={`table-head ${darkname}`}>
                                <div style={{ width: "70%" }}>레퍼러</div>
                                <div style={{ width: "50%" }}>배율</div>
                                <div>Binance 누적 정산액</div>
                                <div>Binance 정산액</div>
                                <div>Binance ID</div>
                                <div>OKX 누적 정산액</div>
                                <div>OKX 정산액</div>
                                <div>OKX ID</div>
                                <div style={{ width: "50%" }}>심사</div>
                            </div>
                            <div className='table-body' style={{ minHeight: "600px" }}>
                                {
                                    spinner
                                        ?
                                        <div className='spinner-modal'>
                                            <SyncLoader color="#F5AC1D" />
                                        </div>
                                        :
                                        slice_data && slice_data.length === 0
                                            ?
                                            <div className='data-nofound'>
                                                <img src='/img/img_nofound.png'></img>
                                                <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                                <p>NO RECORDS FOUND.</p>
                                            </div>
                                            :
                                            <>
                                                {
                                                    slice_data.map(function (a, i) {
                                                        return (
                                                            <SettlementItem key={i} item={a} darkname={darkname} setClick_modal={setClick_modal} />
                                                        )
                                                    })
                                                }
                                                <NewPage initialData={staySettlement} page={page} setPage={setPage} maxFirstPage={5} />
                                            </>

                                }
                            </div>
                        </div>
                    </div>
                    <div className='modal-2-3'>
                        <div className={`modal-2-3-btn`} onClick={() => setClick_modal(false)} >닫기</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function SettlementItem({ item, darkname, setClick_modal }) {
    const onCheck = async () => {
        if (window.confirm("심사 승인을 하시겠습니까?")) {
            await axios.post('https://api.referral.bitket.io/api/v1/broker/admin/get/settlement/today/confirm', {
                withCredentials: true,
                id: item.seq
            }).then((response) => {
                let tmp = response.data
                if (tmp?.c === 1000) {
                    setClick_modal(e => !e)
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    return (
        <div className={`table-body-tr ${darkname}`}>
            <div className='td' style={{ width: "70%" }}>{item.broker}</div>
            <div className='td' style={{ width: "50%" }}>{item.rebate_rate ?? '-'}</div>
            <div className='td right'>{item.binance_volume}</div>
            <div className='td right'>{item.binance_income}</div>
            <div className='td right'>{item.binance_tx_id ?? '-'}</div>
            <div className='td right'>{item.okx_volume}</div>
            <div className='td right'>{item.okx_income}</div>
            <div className='td right'>{item.okx_tx_id ?? '-'}</div>
            <div className='td' style={{ width: "50%" }}>
                {
                    item.binance_confirm == "WAIT" || item.okx_confirm == "WAIT"
                        ?
                        <div className='withdrawitem-btn' onClick={() => onCheck()}>심사</div>
                        :
                        <div>정산 대기</div>
                }

            </div>
        </div>
    )
}


function changeTime(d) {
    d.setUTCHours(0);
    d.setUTCMinutes(0);
    d.setUTCSeconds(0);
    return d.getTime()
}

export default Admin_Dashboard;